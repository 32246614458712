import {Component, OnInit} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {TranslationService} from '../../services/translation.service';
import {CurrenciesService} from './currencies.service';
import {Currency} from '../../domain/currency';
import {SessionService} from '../../services/session.service';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../../../utils';

@Component({
  selector: 'vit-currencies',
  templateUrl: './currencies.component.html',
  styleUrls: ['./currencies.component.scss']
})
export class CurrenciesComponent implements OnInit {

  currencies: Currency[] = [];

  constructor(public currenciesService: CurrenciesService,
              public snackBar: MatSnackBar,
              public sessionService: SessionService,
              public translationService: TranslationService) {
  }

  ngOnInit() {
    this.currenciesService.fetch().subscribe(
      (currencies: Currency[]) => this.currencies = currencies);
  }

  update(currency: Currency) {
    this.currenciesService.update(currency).subscribe({
      next: () => {
        this.snackBar.open(
          this.translationService.translate("snackBar.saved"),
          null,
          SUCCESS_SNACK_CONFIG,
        );
        this.currenciesService
          .fetch()
          .subscribe(
            (currencies: Currency[]) => (this.currencies = currencies),
          );
      },
      error: () => {
        this.snackBar.open(
          this.translationService.translate("snackBar.error"),
          null,
          ERROR_SNACK_CONFIG,
        );
        this.currenciesService
          .fetch()
          .subscribe(
            (currencies: Currency[]) => (this.currencies = currencies),
          );
      },
    });
  }

}
