import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {FranchiserService} from '../services/franchiser.service';
import {MobileService} from '../services/mobile.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {TranslationService} from '../services/translation.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {SessionService} from '../services/session.service';
import {SystemConfig} from '../config/system-config';
import {FranchiserData} from '../domain/franchiser-data';
import {QuestionContainer} from '../domain/question-container';
import {DropdownQuestion} from '../domain/question-dropdown';
import {Permission} from '../domain/permission';
import {TextareaQuestion} from '../domain/question-textarea';
import {TextboxQuestion} from '../domain/question-textbox';
import {Franchiser} from '../domain/franchiser';
import {CheckboxQuestion} from '../domain/question-checkbox';
import {FormUtils} from '../utils/form-utils';
import {CustomerTreeService} from '../services/customer-tree.service';
import {ColsComponent} from '../cols/cols.component';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../../utils';
import {TreeNodeType} from '../domain/tree-node-type';

@Component({
  selector: 'vit-franchiser',
  templateUrl: './franchiser.component.html',
  styleUrls: ['./franchiser.component.scss']
})
export class FranchiserComponent implements OnInit {

  @HostBinding('class.is-mobile')
  get isMobileClass() {
    return this.mobileService.isMobile;
  }

  @HostBinding('class.is-locked')
  get isLocked() {
    return this.franchiserData && (this.franchiserData.franchiser.hardLocked || this.franchiserData.franchiser.softLocked);
  }

  loading: boolean;
  @ViewChild(ColsComponent, { static: false })
  colsComponent: ColsComponent;

  franchiserData: FranchiserData;
  questions: QuestionContainer[] = [];
  parentFranchiserId: number;
  isNew = false;
  hasInsertFranchiserPermission = false;
  hasInsertStationPermission = false;
  hasEditFranchiserLimited = false;

  constructor(public franchiserService: FranchiserService,
              public mobileService: MobileService,
              public snackBar: MatSnackBar,
              public translationService: TranslationService,
              public route: ActivatedRoute,
              public customerTreeService: CustomerTreeService,
              public sessionService: SessionService,
              public router: Router,
              public treeService: CustomerTreeService) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.parentFranchiserId = +params.get(SystemConfig.Routes.Params.PARENT_FRANCHISER_ID);
      this.fetchFranchiser(+params.get(SystemConfig.Routes.Params.FRANCHISER_ID));
    });
    this.hasInsertFranchiserPermission = this.sessionService.hasPermission(Permission.insertFranchiser);
    this.hasInsertStationPermission = this.sessionService.hasPermission(Permission.insertStation);
    this.hasEditFranchiserLimited = this.sessionService.hasPermission(Permission.editFranchiserLimited);
  }

  private fetchFranchiser(franchiserId: number) {
    this.loading = true;
    this.sessionService.selectedFranchiser = null;
    this.franchiserService.fetchFranchiserData(franchiserId).subscribe({
      next: (franchiserData) => {
        this.franchiserData = franchiserData;
        this.sessionService.selectedFranchiser = franchiserData;
        if (franchiserData.franchiser.masterFranchiserID === -1) {
          this.franchiserData.franchiser.masterFranchiserID =
            this.parentFranchiserId;
          this.isNew = true;
        } else {
          this.isNew = false;
        }
        this.reset();
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.snackBar.open(
          this.translationService.translate("snackBar.error"),
          null,
          ERROR_SNACK_CONFIG,
        );
      },
    });
  }

  ngOnInit() {
  }

  save() {
    this.loading = true;
    const franchiser = this.generateFranchiserFromForm();
    const result = this.isNew ? this.franchiserService.insert(franchiser) : this.franchiserService.update(franchiser);
    result.subscribe({
      next: (response: Franchiser | null) => {
        this.snackBar.open(
          this.translationService.translate("snackBar.saved"),
          null,
          SUCCESS_SNACK_CONFIG,
        );
        if (response && response.franchiserID) {
          this.router.navigate([
            "/franchiser",
            response.masterFranchiserID,
            response.franchiserID,
          ]);
        } else {
          this.franchiserData.franchiser = franchiser;
          this.loading = false;
          this.reset();
        }
        this.treeService.fetchTree();
      },
      error: () => {
        this.loading = false;
        this.snackBar.open(
          this.translationService.translate("snackBar.error"),
          null,
          ERROR_SNACK_CONFIG,
        );
      },
    });
  }

  private generateFranchiserFromForm() {
    const masterFranchiserForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'franchiser.franchiser.header.franchiser');
    const companyForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'franchiser.franchiser.header.company');
    const userAccountManagerForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'franchiser.franchiser.header.userAccountManager');
    const crossPaymentForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'franchiser.franchiser.header.crossPayment');
    const cpcForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'franchiser.franchiser.header.customerPlayCode');
    const franchiser: Franchiser = {
      'franchiserID': this.franchiserData.franchiser.franchiserID,
      'masterFranchiserID': +masterFranchiserForm['masterFranchiserID'],
      'openingHours': companyForm['openingHours'],
      'financialAccountID2': companyForm['financialAccountID2'],
      'iban': companyForm['iban'],
      'info2': companyForm['info2'],
      'contactPerson': companyForm['contactPerson'],
      'contactMail': companyForm['contactMail'],
      'contactPhone': companyForm['contactPhone'],
      'legalPerson': companyForm['legalPerson'],
      'legalPhone': companyForm['legalPhone'],
      'salesPerson': companyForm['salesPerson'],
      'name': masterFranchiserForm['name'],
      'test': masterFranchiserForm['test'],
      'masterPIN': masterFranchiserForm['masterPIN'] ? masterFranchiserForm['masterPIN'] : null,
      'softLocked': masterFranchiserForm['softLocked'],
      'hardLocked': masterFranchiserForm['hardLocked'],
      'address': companyForm['address'],
      'financialAccountID': companyForm['financialAccountID'],
      'internalAccountID': companyForm['internalAccountID'],
      'vatid': companyForm['vatid'],
      'email': companyForm['email'],
      'alertEmail': masterFranchiserForm['alertEmail'],
      'info': companyForm['info'],
      'invoiceDeliveryType': +companyForm['invoiceDeliveryType'],
      'paymentType': +companyForm['paymentType'],
      'invoicePaymentType': +companyForm['invoicePaymentType'],
      'crossPaymentID': crossPaymentForm['crossPaymentID'],
      'userAccountManagerID': userAccountManagerForm['userAccountManagerID'],
      'allowAnonymousCPC': cpcForm['allowAnonymousCPC']
    };
    return franchiser;
  }

  reset() {
    this.resetFranchiserQuestions();
  }

  resetFranchiserQuestions(): any {
    this.questions = [];
    const franchiser: Franchiser = this.franchiserData.franchiser;
    const franchiserContainer1 = new QuestionContainer('franchiser.franchiser.header.franchiser', [
      new TextboxQuestion({
        key: 'name',
        label: 'franchiser.franchiser.name',
        value: franchiser.name,
        editPermission: Permission.insertFranchiser,
      }),
      new DropdownQuestion({
        key: 'masterFranchiserID',
        label: 'franchiser.franchiser.masterFranchiserID',
        value: franchiser.masterFranchiserID ? franchiser.masterFranchiserID.toString() : null,
        viewPermission: this.franchiserData.lstMasterFranchisers.size <= 1 ? Permission.notAllowed : null,
        editPermission: Permission.changeMasterFranchiser,
        options: Array.from(this.franchiserData.lstMasterFranchisers.entries()).map(([key, value]) => ({
          key,
          value
        }))
      }),
      new TextareaQuestion({
        key: 'franchiserHierarchy',
        minRows: 5,
        label: 'franchiser.franchiser.franchiserHierarchy',
        value: this.franchiserData.franchiserHierarchy,
        disabled: true
      }),
      new TextboxQuestion({
        key: 'masterPIN',
        label: 'franchiser.franchiser.masterPIN',
        editPermission: Permission.insertFranchiser,
        value: franchiser.masterPIN
      }),
      new TextareaQuestion({
        key: 'alertEmail',
        label: 'franchiser.franchiser.alertEmail',
        value: franchiser.alertEmail,
        minRows: 2,
        editPermission: Permission.editAdvancedAdminSettings
      }),
      new CheckboxQuestion({
        key: 'test',
        label: 'franchiser.franchiser.test',
        value: franchiser.test,
        editPermission: Permission.editAdvancedAdminSettings
      }),
      new CheckboxQuestion({
        key: 'softLocked',
        label: 'franchiser.franchiser.softLocked',
        value: franchiser.softLocked,
        editPermissionsSome: [Permission.lockFranchiser, Permission.editFranchiserLimitedSoftLock]
      }),
      new CheckboxQuestion({
        key: 'hardLocked',
        label: 'franchiser.franchiser.hardLocked',
        value: franchiser.hardLocked,
        viewPermission: Permission.editAdvancedAdminSettings
      })]);
    franchiserContainer1.addSubmit('station.station.showInTree',
      () => this.customerTreeService.showTreeNode.next(
        {id: this.franchiserData.franchiser.franchiserID, type: TreeNodeType.FRANCHISER}));
    this.questions.push(franchiserContainer1);
    const franchiserContainer5 = new QuestionContainer('franchiser.franchiser.header.company', [
      new TextareaQuestion({
        key: 'address',
        label: 'franchiser.franchiser.address',
        value: franchiser.address,
        editPermission: Permission.insertFranchiser,
        minRows: 6
      }),
      new TextareaQuestion({
        key: 'email',
        label: 'franchiser.franchiser.email',
        value: franchiser.email,
        minRows: 2,
        editPermission: Permission.insertFranchiser
      }),
      new TextboxQuestion({
        key: 'contactPerson',
        label: 'franchiser.franchiser.contactPerson',
        editPermission: Permission.insertFranchiser,
        value: franchiser.contactPerson
      }),
      new TextboxQuestion({
        key: 'contactMail',
        label: 'franchiser.franchiser.contactMail',
        editPermission: Permission.insertFranchiser,
        value: franchiser.contactMail
      }),
      new TextboxQuestion({
        key: 'contactPhone',
        label: 'franchiser.franchiser.contactPhone',
        editPermission: Permission.insertFranchiser,
        value: franchiser.contactPhone
      }),
      new TextboxQuestion({
        key: 'legalPerson',
        label: 'franchiser.franchiser.legalPerson',
        editPermission: Permission.insertFranchiser,
        value: franchiser.legalPerson
      }),
      new TextboxQuestion({
        key: 'legalPhone',
        label: 'franchiser.franchiser.legalPhone',
        editPermission: Permission.insertFranchiser,
        value: franchiser.legalPhone
      }),
      new TextboxQuestion({
        key: 'salesPerson',
        label: 'franchiser.franchiser.salesPerson',
        editPermission: Permission.insertFranchiser,
        value: franchiser.salesPerson
      }),
      new TextareaQuestion({
        key: 'info',
        label: 'franchiser.franchiser.info',
        editPermission: Permission.insertFranchiser,
        value: franchiser.info,
        minRows: 6
      }),
      new TextareaQuestion({
        key: 'info2',
        label: 'franchiser.franchiser.info2',
        editPermission: Permission.insertFranchiser,
        value: franchiser.info2,
        minRows: 6
      }),
      new TextboxQuestion({
        key: 'openingHours',
        label: 'franchiser.franchiser.openingHours',
        editPermission: Permission.insertFranchiser,
        value: franchiser.openingHours
      }),
      new TextboxQuestion({
        key: 'vatid',
        label: 'franchiser.franchiser.vatid',
        editPermission: Permission.insertFranchiser,
        value: franchiser.vatid
      }),
      new TextboxQuestion({
        key: 'financialAccountID',
        label: 'franchiser.franchiser.financialAccountID',
        editPermission: Permission.insertFranchiser,
        value: franchiser.financialAccountID
      }),
      new TextboxQuestion({
        key: 'financialAccountID2',
        label: 'franchiser.franchiser.financialAccountID2',
        editPermission: Permission.insertFranchiser,
        value: franchiser.financialAccountID2
      }),
      new TextboxQuestion({
        key: 'internalAccountID',
        label: 'franchiser.franchiser.internalAccountID',
        editPermission: Permission.insertFranchiser,
        value: franchiser.internalAccountID
      }),
      new TextboxQuestion({
        key: 'iban',
        label: 'franchiser.franchiser.iban',
        editPermission: Permission.insertFranchiser,
        value: franchiser.iban
      }),
      new DropdownQuestion({
        key: 'invoicePaymentType',
        label: 'franchiser.franchiser.invoicePaymentType',
        value: franchiser.invoicePaymentType.toString(),
        editPermission: Permission.insertFranchiser,
        options: Array.from(this.franchiserData.lstInvoicePaymentTypes.entries()).map(([key, value]) => ({
          key,
          value
        }))
      }),
      new DropdownQuestion({
        key: 'invoiceDeliveryType',
        label: 'franchiser.franchiser.invoiceDeliveryType',
        value: franchiser.invoiceDeliveryType.toString(),
        editPermission: Permission.insertFranchiser,
        options: Array.from(this.franchiserData.lstInvoiceDeliveryTypes.entries()).map(([key, value]) => ({
          key,
          value
        }))
      }),
      new DropdownQuestion({
        key: 'paymentType',
        label: 'franchiser.franchiser.paymentType',
        value: franchiser.paymentType.toString(),
        editPermission: Permission.insertFranchiser,
        options: Array.from(this.franchiserData.lstPaymentTypes.entries()).map(([key, value]) => ({
          key,
          value
        }))
      })
    ]);
    this.questions.push(franchiserContainer5);

    const franchiserContainer7 = new QuestionContainer('franchiser.franchiser.header.statistics', [
      new TextareaQuestion({
        key: 'franchiserID',
        label: 'franchiser.franchiser.franchiserID',
        value: this.franchiserData.franchiser.franchiserID,
        disabled: true
      }),
      new TextareaQuestion({
        key: 'numFranchisers',
        label: 'franchiser.franchiser.treeStats.numFranchisers',
        value: this.franchiserData.treeStats.get(Franchiser.TreeStats.NUM_FRANCHISERS),
        disabled: true
      }),
      new TextareaQuestion({
        key: 'numStations',
        label: 'franchiser.franchiser.treeStats.numStations',
        value: this.franchiserData.treeStats.get(Franchiser.TreeStats.NUM_STATIONS),
        disabled: true
      }),
      new TextareaQuestion({
        key: 'numTerminals',
        label: 'franchiser.franchiser.treeStats.numTerminals',
        value: this.franchiserData.treeStats.get(Franchiser.TreeStats.NUM_TERMINALS),
        disabled: true
      }),
      new TextareaQuestion({
        key: 'numShops',
        label: 'franchiser.franchiser.treeStats.numShops',
        value: this.franchiserData.treeStats.get(Franchiser.TreeStats.NUM_SHOPS),
        disabled: true
      }),
      new TextareaQuestion({
        key: 'numWebshops',
        label: 'franchiser.franchiser.treeStats.numWebshops',
        value: this.franchiserData.treeStats.get(Franchiser.TreeStats.NUM_WEBSHOPS),
        disabled: true
      }),
      new TextareaQuestion({
        key: 'numWebBridges',
        label: 'franchiser.franchiser.treeStats.numWebBridges',
        value: this.franchiserData.treeStats.get(Franchiser.TreeStats.NUM_WEBBRIDGES),
        disabled: true
      }),
      new TextareaQuestion({
        key: 'numCashiers',
        label: 'franchiser.franchiser.treeStats.numCashiers',
        value: this.franchiserData.treeStats.get(Franchiser.TreeStats.NUM_CASHIERS),
        disabled: true
      }),
      new TextareaQuestion({
        key: 'numLocked',
        label: 'franchiser.franchiser.treeStats.numLocked',
        value: this.franchiserData.treeStats.get(Franchiser.TreeStats.NUM_LOCKED),
        disabled: true
      })
    ]);
    this.questions.push(franchiserContainer7);

    const franchiserContainer3 = new QuestionContainer('franchiser.franchiser.header.userAccountManager', [
      new TextareaQuestion({
        key: 'userAccountManagerDescription',
        label: 'franchiser.franchiser.userAccountManagerDescription',
        value: this.franchiserData.userAccountManagerDescription,
        disabled: true
      }),
      new TextareaQuestion({
        key: 'franchiserHierarchy',
        label: 'franchiser.franchiser.userAccountManagerID',
        value: franchiser.userAccountManagerID,
        disabled: true
      })], null, franchiser.userAccountManagerID != null ? null : Permission.notAllowed);
    this.questions.push(franchiserContainer3);

    const franchiserContainer8 = new QuestionContainer('franchiser.franchiser.header.customerPlayCode', [
      new CheckboxQuestion({
        key: 'allowAnonymousCPC',
        label: 'franchiser.franchiser.allowAnonymousCPC',
        editPermission: Permission.insertFranchiser,
        value: this.franchiserData.franchiser.allowAnonymousCPC
      })]);
    this.questions.push(franchiserContainer8);

    const franchiserContainer6 = new QuestionContainer('franchiser.franchiser.header.crossPayment', [
      new TextareaQuestion({
        key: 'crossPaymentID',
        label: 'franchiser.franchiser.crossPaymentID',
        editPermission: Permission.insertFranchiser,
        value: this.franchiserData.franchiser.crossPaymentID
      })]);
    this.questions.push(franchiserContainer6);
    const franchiserContainer4 = new QuestionContainer('franchiser.franchiser.header.webuserlist', [
      new TextareaQuestion({
        key: 'usersForFranchiser',
        label: 'franchiser.franchiser.usersForFranchiser',
        value: this.franchiserData.usersForFranchiser ? this.franchiserData.usersForFranchiser.join('\n') : '',
        disabled: true,
        minRows: Math.min(this.franchiserData.usersForFranchiser.length || 1, 5),
        editPermission: Permission.insertFranchiser,
        viewPermission: Permission.insertWebUser
      }),
    ], null, Permission.insertWebUser);
    this.questions.push(franchiserContainer4);
  }
}
