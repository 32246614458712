<mat-dialog-content #dialogContent>
  <mat-tab-group (selectedTabChange)="setLanguage($event)">
    <mat-tab label="De"></mat-tab>
    <mat-tab label="En"></mat-tab>
    <mat-tab label="Tr"></mat-tab>
    <mat-tab label="Hr"></mat-tab>
    <mat-tab label="Ro"></mat-tab>
    <mat-tab label="Sq"></mat-tab>
  </mat-tab-group>

  <div class="component" *ngIf="notification">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'notificationEditor.subject' | translate }}</mat-label>
      <input matInput [(ngModel)]="subjectObj.value"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'notificationEditor.description' | translate }}</mat-label>
      <input matInput [(ngModel)]="notification.description"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'notificationEditor.category' | translate }}</mat-label>
      <input matInput [(ngModel)]="notification.category"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'notificationEditor.identifier' | translate }}</mat-label>
      <input matInput [(ngModel)]="notification.identifier"/>
    </mat-form-field>
    <mat-form-field appearance="outline" class="date-form-field">
      <mat-label>{{ 'notificationEditor.validFrom' | translate }}</mat-label>
      <vit-date-input [(ngModel)]="notification.validFrom"></vit-date-input>
      <mat-icon (click)="notification.validFrom = null" style="cursor:pointer;" matSuffix class="clear-icon">clear</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="outline" class="date-form-field">
      <mat-label>{{ 'notificationEditor.validUntil' | translate }}</mat-label>
      <vit-date-input [(ngModel)]="notification.validUntil"></vit-date-input>
      <mat-icon (click)="notification.validUntil = null" style="cursor:pointer;" matSuffix class="clear-icon">clear</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'notificationEditor.franchiserIDRecipients' | translate }}</mat-label>
      <mat-select multiple [(ngModel)]="notification.franchiserIDRecipients">
        <mat-option *ngFor="let franchiser of franchisers" [value]="franchiser.key">{{ franchiser.value }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'notificationEditor.recipientType' | translate }}</mat-label>
      <mat-select [(ngModel)]="notification.recipientType">
        <mat-option [value]="0">{{ 'notificationEditor.recipientTypeAll' | translate }}</mat-option>
        <mat-option [value]="1">{{ 'notificationEditor.recipientTypeSome' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="notification.recipientType === 1">
      <mat-label>{{ 'notificationEditor.customerIDRecipients' | translate: [notification.customerIDRecipients?.length?.toString() || '0'] }}</mat-label>
      <input matInput placeholder="12,351,5321" [(ngModel)]="notification.customerIDRecipientsStr" (ngModelChange)="customerIdRecipientsChange()"/>
    </mat-form-field>
  </div>

  <div class="component" *ngFor="let component of components; let idx = index">
    <div class="component-type">
      <span>{{ idx + 1 }}. {{ component.componentType }}</span>
      <div style="display: flex; align-items: center">
        <input style="width: 50px" type="number" placeholder="Order" [(ngModel)]="component.order"/>
        <button mat-icon-button (click)="removeComponent(idx)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <ng-container *ngIf="component.componentType === 'text'">
      <div class="editor-wrapper">
        <textarea *ngIf="component.contentObj" cdkTextareaAutosize cdkAutosizeMinRows="4"
                  [(ngModel)]="component.contentObj.value"></textarea>
        <markdown *ngIf="component.contentObj" [data]="component.contentObj.value"></markdown>
      </div>
    </ng-container>

    <ng-container *ngIf="component.componentType === 'link'">
      <mat-form-field>
        <mat-label>{{ 'notificationEditor.type' | translate }}</mat-label>
        <mat-select [(ngModel)]="component.type">
          <mat-option value="external">{{ 'notificationEditor.types.external' | translate }}</mat-option>
          <mat-option value="internal">{{ 'notificationEditor.types.internal' | translate }}</mat-option>
          <mat-option value="submit">{{ 'notificationEditor.types.submit' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="component.type === 'external'">
        <mat-label>{{ 'notificationEditor.url' | translate }}</mat-label>
        <input matInput [(ngModel)]="component.url"/>
      </mat-form-field>
      <mat-form-field *ngIf="component.type === 'internal'">
        <mat-label>{{ 'notificationEditor.target' | translate }}</mat-label>
        <input matInput [(ngModel)]="component.target"/>
      </mat-form-field>
      <mat-form-field *ngIf="component.type === 'internal'">
        <mat-label>{{ 'notificationEditor.data' | translate }}</mat-label>
        <input matInput [(ngModel)]="component.data"/>
      </mat-form-field>
      <div class="editor-wrapper">
        <textarea *ngIf="component.textObj" cdkTextareaAutosize cdkAutosizeMinRows="4"
                  [(ngModel)]="component.textObj.value"></textarea>
        <markdown *ngIf="component.textObj" [data]="component.textObj.value"></markdown>
      </div>
    </ng-container>

    <ng-container *ngIf="component.componentType === 'question'">
      <div class="editor-wrapper">
        <textarea *ngIf="component.textObj" cdkTextareaAutosize cdkAutosizeMinRows="4"
                  [(ngModel)]="component.textObj.value"></textarea>
        <markdown *ngIf="component.textObj" [data]="component.textObj.value"></markdown>
      </div>
      <mat-checkbox [(ngModel)]="component.required">{{ 'notificationEditor.required' | translate }}</mat-checkbox>
      <mat-form-field>
        <mat-label>{{ 'notificationEditor.type' | translate }}</mat-label>
        <mat-select [(ngModel)]="component.type">
          <mat-option value="radio_horizontal">{{ 'notificationEditor.types.radio_horizontal' | translate }}
          </mat-option>
          <mat-option value="radio">{{ 'notificationEditor.types.radio' | translate }}</mat-option>
          <mat-option value="feedback">{{ 'notificationEditor.types.feedback' | translate }}</mat-option>
          <mat-option value="checkbox">{{ 'notificationEditor.types.checkbox' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="answer-box" *ngFor="let answer of component.answersObj; let aIdx = index">
        <div class="editor-wrapper">
          <mat-form-field style="flex: 0 0 100px">
            <mat-label>{{ 'notificationEditor.answerKey' | translate }}</mat-label>
            <input matInput [(ngModel)]="answer.key" (ngModelChange)="answerChange(answer, aIdx, component)"/>
          </mat-form-field>
          <textarea cdkTextareaAutosize cdkAutosizeMinRows="4" [(ngModel)]="answer.value"
                    (ngModelChange)="answerChange(answer, aIdx, component)"></textarea>
          <markdown [data]="answer.value"></markdown>
          <button style="flex: 0 0 70px" mat-icon-button (click)="removeAnswer(aIdx, component)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <button style="margin: 20px 0; width: 250px;" mat-raised-button (click)="addAnswer(component)">
        <mat-icon>add</mat-icon>
        {{ 'notificationEditor.addAnswer' | translate }}
      </button>
      <mat-form-field *ngIf="component.type === 'feedback'">
        <mat-label>{{ 'notificationEditor.maxChars' | translate }}</mat-label>
        <input matInput type="number" [(ngModel)]="component.maxChars"/>
      </mat-form-field>

    </ng-container>
  </div>
  <div class="action-buttons">
    <button mat-raised-button [matMenuTriggerFor]="appMenu">
      {{ 'notificationEditor.newComponent' | translate }}
    </button>
    <div>
      <button mat-button (click)="close()">{{ 'notificationEditor.cancel' | translate }}</button>
      <button mat-raised-button (click)="save()">{{ 'notificationEditor.save' | translate }}</button>
    </div>
  </div>
</mat-dialog-content>
<mat-menu #appMenu="matMenu" yPosition="above" class="edit-actions-menu-dialog">
  <button mat-raised-button (click)="newComponent('text')">{{ 'notificationEditor.componentTypes.text' | translate }}
  </button>
  <br>
  <button mat-raised-button (click)="newComponent('link')">{{ 'notificationEditor.componentTypes.link' | translate }}
  </button>
  <br>
  <button mat-raised-button
          (click)="newComponent('question')">{{ 'notificationEditor.componentTypes.question' | translate }}
  </button>
  <br>
</mat-menu>
