import {Component, ElementRef, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslationService} from '../../../services/translation.service';
import {SessionService} from '../../../services/session.service';
import {NotificationComponent, NotificationContent, NotificationEntry} from '../notificationEntry';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {NotificationEditorService} from '../notification-editor.service';
import {sortAlphabetically} from '../../../../utils';
import {CustomerTreeService} from '../../../services/customer-tree.service';

@Component({
  selector: 'vit-edit-notification-dialog',
  templateUrl: './edit-notification-dialog.component.html',
  styleUrls: ['./edit-notification-dialog.component.scss']
})
export class EditNotificationDialog implements OnInit {

  @ViewChild('dialogContent') dialogContent!: ElementRef;
  language = 'de';
  notification: NotificationEntry;
  subject: NotificationContent[] = [];
  subjectObj: NotificationContent;
  components: NotificationComponent[] = [];

  franchisers: {key: number, value: string}[] = [];

  constructor(public translationService: TranslationService,
              public sessionService: SessionService,
              public notificationEditorService: NotificationEditorService,
              public customerTreeService: CustomerTreeService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: { notificationId: number },
              public dialogRef: MatDialogRef<EditNotificationDialog>,
              public route: ActivatedRoute) {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.components.sort((a, b) => a.order - b.order);
    this.components.forEach((c, i) => c.order = i + 1);
    this.notificationEditorService.saveInbox(this.notification).subscribe(res => {
      this.dialogRef.close(this.notification);
    });
  }

  newSegment() {
  }

  ngOnInit() {

    this.customerTreeService.tree.subscribe(node => {
      this.franchisers = [];
      this.franchisers.push(...this.sessionService.franchisers.map(f => {
        return {
          key: f.node.id, value: f.node.label
        };
      }));
      sortAlphabetically(this.franchisers, 'value');
    });

    if (!this.data.notificationId) {
      this.notification = {
        subject: [],
        description: '',
        category: '',
        identifier: '',
        body: {
          components: []
        },
        franchiserIDRecipients: [],
        customerIDRecipients: [],
        customerIDRecipientsStr: '',
        recipientType: 0,
        validFrom: '',
        validUntil: ''
      };
      this.subject = this.notification?.subject || [];
      this.components = this.notification?.body.components || [];
      this.setLocalizedData();
      console.log('initialized new', this.notification);
      return;
    }
    this.notificationEditorService.loadNotification(this.data.notificationId).subscribe(res => {
      this.notification = res;
      console.log('LOADED', res);
      this.subject = this.notification?.subject || [];
      this.components = this.notification?.body.components || [];
      this.setLocalizedData();
    });
  }

  setLanguage(e: MatTabChangeEvent) {
    this.language = e.tab.textLabel.toLowerCase();
    this.setLocalizedData();
  }

  private setLocalizedData() {
    this.subjectObj = this.subject.find(s => s.language === this.language);
    if (!this.subjectObj) {
      this.subjectObj = {language: this.language, value: ''};
      this.subject.push(this.subjectObj);
    }
    this.components.forEach(c => {
      if (c.content) {
        let content = c.content?.find(cc => cc.language === this.language);
        if (!content) {
          content = {language: this.language, value: ''};
          c.content.push(content);
        }
        c.contentObj = content;
      }
      if (c.text) {
        let text = c.text?.find(cc => cc.language === this.language);
        if (!text) {
          text = {language: this.language, value: ''};
          c.text.push(text);
        }
        c.textObj = text;
      }
      if (c.answers) {
        c.answersObj = (c.answers || []).map(a => ({
          key: a.key,
          value: a.text?.find(cc => cc.language === this.language)?.value || ''
        }));
        c.answers.forEach(a => {
          if (a.text.filter(t => t.language === this.language).length === 0) {
            a.text.push({language: this.language, value: ''});
          }
        });
      }
    });
  }

  newComponent(componentType: string) {
    const content = [];
    let contentObj: any;
    const text = [];
    let textObj: any;
    let type: string;
    let url: string;
    let target: string;
    let answers: any[];
    let answersObj: any[];
    switch (componentType) {
      case 'text':
        contentObj = {language: this.language, value: ''};
        content.push(contentObj);
        break;
      case 'link':
        textObj = {language: this.language, value: ''};
        text.push(textObj);
        type = 'external';
        url = '';
        target = '';
        break;
      case 'question':
        textObj = {language: this.language, value: ''};
        text.push(textObj);
        type = 'radio_horizontal';
        answers = [{key: '', text: [{language: this.language, value: ''}]}]; // TODO: enable add/remove answers
        answersObj = [{key: '', value: ''}];
        break;
    }

    this.components.push({
      componentType,
      required: false,
      order: this.components.length,
      content: content,
      contentObj,
      text: text,
      textObj,
      feedbackValue: '',
      selectedKey: '',
      maxChars: 0,
      type,
      answers,
      answersObj,
      url,
      target
    });
    setTimeout(() => {
      this.dialogContent.nativeElement.scrollTop = this.dialogContent.nativeElement.scrollHeight;
    }, 100);
  }

  answerChange(answer: { key: string; value: string }, answerIndex: number, component: NotificationComponent) {
    component.answers[answerIndex].key = answer.key;
    component.answers[answerIndex].text.filter(t => t.language === this.language)[0].value = answer.value;
  }

  removeAnswer(aIdx: number, component: NotificationComponent) {
    component.answers.splice(aIdx, 1);
    component.answersObj.splice(aIdx, 1);
  }

  addAnswer(component: NotificationComponent) {
    const languages = component.answers[0]?.text.map(t => t.language) || [this.language];
    component.answers.push({key: '', text: languages.map(l => ({language: l, value: ''}))});
    component.answersObj.push({key: '', value: ''});
  }

  removeComponent(idx: number) {
    this.components.splice(idx, 1);
  }

  customerIdRecipientsChange() {
    this.notification.customerIDRecipients = this.notification.customerIDRecipientsStr.split(',').map(s => +s);
  }
}
