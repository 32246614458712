import { Component } from "@angular/core";
import {
  isNumber,
  isNullOrUndefined,
  loadColumnVisibility,
  storeColumnVisibility,
  getContextMenuItems,
} from "../../../utils";
import { HttpService } from "../../services/http.service";
import { QueryFilter } from "../../domain/query-filter";
import { InkassoService } from "./inkasso.service";
import { DatePipe, DecimalPipe } from "@angular/common";
import { TranslationService } from "../../services/translation.service";
import { GridReadyEvent } from "ag-grid-community";
import { MobileService } from "../../services/mobile.service";

@Component({
  selector: "vit-inkasso",
  templateUrl: "./inkasso.component.html",
  styleUrls: ["./inkasso.component.scss"],
})
export class InkassoComponent {
  query: QueryFilter;

  rowData;
  columnDefs;
  gridOptions;
  colApi;

  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(
    private httpService: HttpService,
    private inkassoService: InkassoService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private translationService: TranslationService,
    private mobileService: MobileService
  ) {
    this.columnDefs = this.generateColumnDefs();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    this.colApi = event.columnApi;
    loadColumnVisibility('cashTimeline', event);
    this.onSimpleViewChange(this.mobileService.isMobile);
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.inkassoService.fetchInkassoList(query);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('inkasso.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('inkasso.stationID')
      },
      {
        headerName: this.translationService.translate('inkasso.name'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'name',
        width: 150,
        headerTooltip: this.translationService.translate('inkasso.name')
      },
      {
        headerName: this.translationService.translate('inkasso.balance'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'balance',
        width: 60,
        valueFormatter: params => params.value === 0 ? ' ' : this.decimalFormatter(params.value),
        cellClass: 'text-align-right',
        headerTooltip: this.translationService.translate('inkasso.balance')
      },
      {
        headerName: this.translationService.translate('inkasso.oldBalance'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'oldBalance',
        width: 60,
        valueFormatter: params => params.value === 0 ? ' ' : this.decimalFormatter(params.value),
        cellClass: 'text-align-right',
        headerTooltip: this.translationService.translate('inkasso.oldBalance')
      },
      {
        headerName: this.translationService.translate('inkasso.cashbookDate'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashbookDate',
        width: 160,
        valueFormatter: this.dateFormatter.bind(this),
        headerTooltip: this.translationService.translate('inkasso.cashbookDate')
      },
      {
        headerName: this.translationService.translate('inkasso.cashTicketsNI'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashTicketsNI',
        width: 95,
        headerTooltip: this.translationService.translate('inkasso.cashTicketsNI')
      },
      {
        headerName: this.translationService.translate('inkasso.cashStorageTotal'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashStorageTotal',
        valueFormatter: params => params.value === 0 ? ' ' : this.decimalFormatter(params.value),
        width: 95,
        headerTooltip: this.translationService.translate('inkasso.cashStorageTotal')
      },
      {
        headerName: this.translationService.translate('inkasso.openWinnings'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'openWinnings',
        valueFormatter: params => params.value === 0 ? ' ' : this.decimalFormatter(params.value),
        width: 60,
        headerTooltip: this.translationService.translate('inkasso.openWinnings')
      },
      {
        headerName: this.translationService.translate('inkasso.ctPayinAllowed'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'ctPayinAllowed',
        width: 95,
        headerTooltip: this.translationService.translate('inkasso.ctPayinAllowed')
      },
      {
        headerName: this.translationService.translate('inkasso.ctManualVoid'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'ctManualVoid',
        width: 95,
        headerTooltip: this.translationService.translate('inkasso.ctManualVoid')
      },
    ];
  }
  generateGridOptions() {
    return {
      getContextMenuItems: (params) =>
        getContextMenuItems(this.httpService, this.query, params),
      masterDetail: false,
      detailRowHeight: 160,
      rowClassRules: {
        bold: (params) => params.data.deltaCredit === 0,
        "text-align-right": (params) => isNumber(params.data.value),
        "vit-red": (params) =>
          !isNullOrUndefined(params.data.note) && params.data.note !== "",
        "vit-green": (params) =>
          !isNullOrUndefined(params.data.type) && params.data.type === -2,
        "vit-blue": (params) =>
          !isNullOrUndefined(params.data.type) && params.data.type >= 20,
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: "",
      },
    };
  }

  isLargeNumber(params) {
    return (
      isNumber(params.value) && Math.round(params.value).toString().length >= 8
    );
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, "medium");
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, "0.2-2");
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, "0.2-2") + "%";
  }

  onSimpleViewChange(showSimple: boolean) {
    this.changeColumnVisibility("stationID", true);
    this.changeColumnVisibility("name", true);
    this.changeColumnVisibility("balance", true);
    this.changeColumnVisibility("cashbookDate", !showSimple);
    this.changeColumnVisibility("oldBalance", !showSimple);
    this.changeColumnVisibility("cashTicketsNI", !showSimple);
    this.changeColumnVisibility("cashStorageTotal", !showSimple);
    this.changeColumnVisibility("openWinnings", true);
    this.changeColumnVisibility("ctPayinAllowed", !showSimple);
    this.changeColumnVisibility("ctManualVoid", !showSimple);
  }

  private changeColumnVisibility(key: string, visible: boolean) {
    this.colApi.setColumnVisible(key, visible);
  }
}
