import {Injectable} from '@angular/core';
import {LinkedElement} from '../domain/linked-element';
import {SystemConfig} from '../config/system-config';
import {AccordionModel} from '../domain/accordion-model';
import {Permission} from '../domain/permission';
import {SessionService} from './session.service';
import {last} from 'rxjs';

@Injectable()
export class HomeMenuService {

  menu: AccordionModel<LinkedElement[]>[] = [];
  public readonly CATEGORY_EDITOR_LABEL = 'home.menu.categoryEditor';
  public readonly MATCH_EDITOR_LABEL = 'home.menu.matchEditor';

  constructor(public sessionService: SessionService) {
    this.createMenu();
  }

  hasPermissionForMenuEntry(label: string) {
    const menuEntryByLabel = this.getMenuEntryByLabel(label);
    const hasPermissions = menuEntryByLabel.length > 0
      && menuEntryByLabel.every(m => this.filter([m]).length > 0);
    return hasPermissions;
  }

  private getMenuEntryByLabel(label: string) {
    return this.menu
      .map(m => m.content
        .filter(c => c.label === label))
      .filter(m => m.length > 0)
      .reduce((a, b) => a.concat(b), []);
  }

  createMenu() {
    this.menu = [new AccordionModel('home.menu.turnover', this.filter(this.getTurnoverMenuContent()), 'keyboard_arrow_right'),
      new AccordionModel('home.menu.accounting', this.filter(this.getAccountingMenuContent()), 'keyboard_arrow_right'),
      new AccordionModel('home.menu.overview', this.filter(this.getOverviewMenuContent()), 'keyboard_arrow_right'),
      new AccordionModel('home.menu.statistics', this.filter(this.getStatisticsMenuContent()), 'keyboard_arrow_right'),
      new AccordionModel('home.menu.results', this.filter(this.getResultsMenuContent()), 'keyboard_arrow_right'),
      new AccordionModel('home.menu.tickets', this.filter(this.getTicketsMenuContent()), 'keyboard_arrow_right'),
      new AccordionModel('home.menu.administration', this.filter(this.getAdministrationMenuContent()), 'keyboard_arrow_right'),
      this.sessionService.hasPermission(Permission.editAdvancedAdminSettings)
      && this.sessionService.hasPermission(Permission.seeBetaFeatures)
        ? new AccordionModel('home.menu.developer', this.filter(this.getDeveloperMenuContent()), 'keyboard_arrow_right')
        : null,
      this.sessionService.hasPermission(Permission.editAdvancedAdminSettings)
      && this.sessionService.hasPermission(Permission.seeBetaFeatures)
        ? new AccordionModel('home.menu.analyticsTools', this.filter(this.getAnalyticsToolsMenuContent()), 'keyboard_arrow_right')
        : null,
      this.sessionService.hasPermission(Permission.editLB2RiskManagement)
        ? new AccordionModel('home.menu.liveAdmin', this.filter(this.getLiveAdminMenuContent()), 'keyboard_arrow_right')
        : null,
      new AccordionModel('home.menu.customersAndAccounts', this.filter(this.getCustomersAndAccountsContent()), 'keyboard_arrow_right'),
      new AccordionModel('home.menu.language', this.filter(this.getLanguageMenuContent()), 'keyboard_arrow_right'),
      this.sessionService.hasPermission(Permission.showVirtualModeControls)
        ? new AccordionModel('home.menu.virtualMode', this.filter(this.getVirtualModeMenuContent()), 'keyboard_arrow_right')
        : null
    ].filter(el => !!el);
  }

  filter(elements: LinkedElement[]): LinkedElement[] {
    return elements.filter(el => {
      if (Array.isArray(el.permission)) {
        return !el.permission || this.sessionService.hasPermission(...el.permission as ReadonlyArray<Permission>);
      }
      return !el.permission || this.sessionService.hasPermission(el.permission as Permission);
    });
  }

  public getCustomersAndAccountsContent(): LinkedElement[] {
    return [{
      label: 'home.menu.customerInfo',
      icon: 'cached',
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CUSTOMER_INFO],
      permission: Permission.seeCustomerAnalysis,
      keywords: ['customer', 'info', 'kunden']
    }, {
      label: 'home.menu.userAccountTimeline',
      icon: '',
      permission: Permission.seeCashOverview,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.USER_ACCOUNT_TIMELINE],
      keywords: ['user', 'account', 'timeline']
    },
    {
      label: 'home.menu.customerHold',
      icon: 'cached',
      permission: Permission.editAdvancedAdminSettings,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CUSTOMER_HOLD],
      keywords: ['kunden', 'hold']
    },
    {
      label: 'home.menu.customerBalanceWeek',
      icon: 'cached',
      permission: Permission.editAdvancedAdminSettings,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CUSTOMER_BALANCE_WEEK],
      keywords: ['kunden', 'bilanz']
    }];
  }

  public getLanguageMenuContent(): LinkedElement[] {
    return [{
      label: 'Deutsch',
      icon: '',
      link: [SystemConfig.Routes.LANGUAGE, 'de'],
      keywords: ['sprache', 'deutsch', 'german']
    }, {
      label: 'English',
      icon: '',
      link: [SystemConfig.Routes.LANGUAGE, 'en'],
      keywords: ['language', 'english', 'englisch']
    }, {
      label: 'Français',
      icon: '',
      link: [SystemConfig.Routes.LANGUAGE, 'fr'],
      keywords: ['language', 'french', 'französisch']
    }, {
      label: 'Pусский',
      icon: '',
      link: [SystemConfig.Routes.LANGUAGE, 'ru'],
      keywords: ['language', 'russisch', 'russian']
    }, {
      label: 'Espanol',
      icon: '',
      link: [SystemConfig.Routes.LANGUAGE, 'es'],
      keywords: ['language', 'spanisch', 'spanish']
    },
    {
      label: 'Português',
      icon: '',
      link: [SystemConfig.Routes.LANGUAGE, 'pt'],
      keywords: ['language', 'portugiesisch', 'portuguese']
    }];
  }

  public getDeveloperMenuContent(): LinkedElement[] {
    return [
      {
        label: 'home.menu.notificationEditor',
        icon: 'post_add',
        link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.NOTIFICATION_EDITOR],
        permission: Permission.editAdvancedAdminSettings,
        keywords: ['notification', 'editor', 'post', 'message']
      }, {
        label: 'markdownTest',
        icon: 'cached',
        link: [],
        infoKey: 'test',
        keywords: []
      }
    ];
  }

  public getAnalyticsToolsMenuContent(): LinkedElement[] {
    return [];
  }

  public getLiveAdminMenuContent(): LinkedElement[] {
    return [{
      label: 'home.menu.betLimits',
      icon: 'cached',
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.BET_LIMITS],
      permission: Permission.editLB2RiskManagement,
      keywords: ['bet', 'limit']
    }, {
      label: this.CATEGORY_EDITOR_LABEL,
      icon: 'cached',
      link: [SystemConfig.Routes.LIVE_ADMIN, SystemConfig.Routes.HomeMenu.CATEGORY_EDITOR],
      permission: Permission.seeBetaFeatures,
      keywords: ['category', 'editor']
    }, {
      label: this.MATCH_EDITOR_LABEL,
      icon: 'cached',
      link: [SystemConfig.Routes.LIVE_ADMIN, SystemConfig.Routes.HomeMenu.MATCH_EDITOR],
      permission: Permission.seeBetaFeatures,
      keywords: ['match', 'editor']
    }];
  }

  public getVirtualModeMenuContent(): LinkedElement[] {
    return [{
      label: 'home.menu.virtualModeLogout',
      icon: 'cached',
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.VIRTUAL_MODE_LOGOUT],
      permission: Permission.showVirtualModeControls,
      keywords: ['virtual', 'mode', 'logout']
    }];
  }

  public getTurnoverMenuContent(): LinkedElement[] {
    return [{
      label: 'home.menu.turnoverAnalysis',
      icon: 'cached',
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.TURNOVER],
      permission: Permission.seeHoldOverview,
      keywords: ['accounting', 'turnover']
    }, {
      label: 'home.menu.dayHoldOverview',
      icon: '',
      permission: Permission.seeDayStatistic,
      link: ['ref'],
      queryParams: {ref: '/home/hold-overview/day'},
      keywords: ['day', 'hold', 'overview']
    }, {
      label: 'home.menu.monthHoldOverview',
      icon: '',
      permission: Permission.seeMonthStatistic,
      link: ['ref'],
      queryParams: {ref: '/home/hold-overview/month'},
      keywords: ['day', 'hold', 'overview']
    }, {
      label: 'home.menu.holdOverviewFranchiserAnalysis',
      icon: 'cached',
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.HOLD_OVERVIEW_FRANCHISER],
      permission: Permission.seeHoldOverview,
      keywords: ['accounting', 'turnover', 'franchiser']
    }, {
      label: 'home.menu.webshopHoldOverview',
      icon: '',
      permission: Permission.seeWebShopHoldOverview,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.WEBSHOP_HOLD_OVERVIEW],
      keywords: ['webshop', 'hold, overview']
    }, {
      label: 'home.menu.numbersTurnover',
      icon: '',
      permission: Permission.seeHoldOverview,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.NUMBERS_HOLD_OVERVIEW],
      keywords: ['numbers', 'lucky', 'six', 'hold, overview']
    }, {
      label: 'home.menu.pokerTurnover',
      icon: '',
      permission: Permission.seeHoldOverview,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.POKER_HOLD_OVERVIEW],
      keywords: ['numbers', 'poker', 'hold, overview']
    }, {
      label: 'home.menu.liveTurnover',
      icon: '',
      permission: Permission.seeHoldOverview,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.LIVE_HOLD_OVERVIEW],
      keywords: ['numbers', 'live', 'hold, overview']
    }];
  }

  public getOverviewMenuContent(): LinkedElement[] {
    return [{
      label: 'home.menu.stationOverview',
      icon: 'account_balance',
      permission: Permission.seeStationOverview,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.STATION_OVERVIEW],
      keywords: ['station', 'overview']
    }, {
      label: 'home.menu.terminalCashInOut',
      icon: 'account_balance',
      permission: Permission.seeCashOverview,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.TERMINAL_CASH],
      keywords: ['terminal', 'cash-in', 'cashin', 'out', 'cash', 'cash-out']
    }, {
      label: 'home.menu.cashTimeline',
      icon: 'account_balance',
      permission: Permission.seeCashOverview,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CASH_TIMELINE],
      keywords: ['terminal', 'cash-in', 'cashin', 'out', 'cash', 'cash-out']
    }, {
      label: 'home.menu.recyclerTimeline',
      icon: 'cached',
      permission: Permission.seeCashOverview,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.RECYCLER_TIMELINE],
      keywords: ['recycler', 'timeline']
    },
      {
        label: 'home.menu.cashTicketOverview',
        icon: 'account_balance',
        permission: Permission.seeCashOverview,
        link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CASH_TICKET_OVERVIEW],
        keywords: ['cash-ticket', 'cashticket', 'cash', 'overview']
      }, {
        label: 'home.menu.cashErrors',
        icon: 'account_balance',
        permission: Permission.seeCashOverview,
        link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CASH_ERRORS],
        keywords: ['cash-error', 'error', 'cash', 'overview']
      }, {
        label: 'home.menu.currencies',
        icon: '',
        permission: Permission.seeCashOverview,
        link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CURRENCIES],
        keywords: ['currencies']
      }, {
        label: 'home.menu.jackpotOverview',
        icon: '',
        permission: Permission.seeJackpotOverview,
        link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.JACKPOT_OVERVIEW],
        keywords: ['jackpot', 'overview']
      }];
  }

  public getStatisticsMenuContent(): LinkedElement[] {
    return [];
  }

  public getResultsMenuContent(): LinkedElement[] {
    return [{
      label: 'home.menu.resultsRF2',
      icon: 'account_balance',
      permission: Permission.seeRaceResult,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.RESULTS_RF2],
      keywords: ['race', 'results', 'smart', 'ergebnisse']
    }, {
      label: 'home.menu.resultsBB1',
      icon: 'account_balance',
      permission: Permission.seeBB1GameResult,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.RESULTS_BB1],
      keywords: ['bet', 'results', 'keno', 'ergebnisse']
    }, {
      label: 'home.menu.resultsPB1',
      icon: 'account_balance',
      permission: Permission.seePB1GameResult,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.RESULTS_PB1],
      keywords: ['bet', 'results', 'poker', 'ergebnisse']
    }];
  }

  public getTicketsMenuContent(): LinkedElement[] {
    return [{
      label: 'home.menu.ticketSearch',
      icon: 'account_balance',
      permission: Permission.seeTicketlist,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.TICKET_SEARCH],
      keywords: ['ticket', 'search', 'suche']
    }, {
      label: 'home.menu.ticketListStations',
      icon: '',
      permission: Permission.seeTicketlist,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.TICKET_LIST_STATIONS],
      keywords: ['ticket, tickets, list']
    }];
  }

  public getAccountingMenuContent(): LinkedElement[] {
    return [{
      label: 'home.menu.balance',
      icon: 'account_balance',
      permission: Permission.seeShopCashbook,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.BALANCE],
      keywords: ['balance', 'bilanz']
    }, {
      label: 'home.menu.balanceFranchiser',
      icon: 'account_balance',
      permission: Permission.seeShopCashbook,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.BALANCE_FRANCHISER],
      keywords: ['balance', 'bilanz', 'franchiser']
    }, {
      label: 'home.menu.balanceGroup',
      icon: 'account_balance',
      permission: Permission.editAdvancedAdminSettings,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.BALANCE_GROUP],
      keywords: ['balance', 'bilanz', 'group']
    }, {
      label: 'home.menu.balanceMonth',
      icon: 'account_balance',
      permission: Permission.seeBalanceOverview,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.BALANCE_MONTH],
      keywords: ['balance', 'bilanz', 'monat', 'month']
    }, {
      label: 'home.menu.balanceCashTotal',
      icon: 'account_balance',
      permission: Permission.seeShopCashbook,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.BALANCE_CASH_TOTAL],
      keywords: ['balance', 'cash', 'total', 'bilanz']
    }, {
      label: 'home.menu.cashbook',
      icon: 'account_balance',
      permission: Permission.seeCashbook,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CASHBOOK],
      keywords: ['cashbook', 'cash', 'book', 'kassa', 'kassabuch', 'buch']
    }, {
      label: 'home.menu.cashbookControlling',
      icon: 'account_balance',
      permission: Permission.seeCashbook,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CASHBOOK_CONTROLLING],
      keywords: ['cashbook', 'cash', 'book', 'kassa', 'kassabuch', 'buch', 'controlling']
    }, {
      label: 'home.menu.inkasso',
      icon: 'account_balance',
      permission: Permission.seeCashbook,
      keywords: ['inkasso', 'list', 'inkassoliste'],
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.INKASSO]
    }, {
      label: 'home.menu.balanceLivesports',
      icon: 'account_balance',
      permission: Permission.seeCashbook,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.BALANCE_LIVESPORTS],
      keywords: ['balance', 'live', 'livesports']
    }, {
      label: 'home.menu.thirdPartyBalance',
      icon: 'account_balance',
      permission: Permission.seeShopCashbook,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.BALANCE_THIRD_PARTY],
      keywords: ['cashbook', 'cash', 'book', 'kassa', 'kassabuch', 'buch', 'casino', 'third', 'party']
    }];
  }

  public getAdministrationMenuContent(): LinkedElement[] {
    return [{
      label: 'home.menu.changePassword',
      icon: 'person',
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CHANGE_PASSWORD],
      keywords: ['change', 'password', 'user']
    }, {
      label: 'home.menu.groupJackpots',
      icon: 'add_box',
      permission: Permission.insertJackpot,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.GROUP_JACKPOTS],
      keywords: ['administration', 'jackpot', 'gruppen']
    }, {
      label: 'home.menu.userManagement',
      icon: 'add_box',
      permission: Permission.insertWebUser,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.USER_MANAGEMENT],
      keywords: ['user', 'management', 'benutzer', 'verwalten']
    }, {
      label: 'home.menu.roleManagement',
      icon: 'add_box',
      permission: Permission.editAdvancedAdminSettings,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.ROLE_MANAGEMENT],
      keywords: ['rollen', 'role', 'management', 'verwalten']
    }, {
      label: 'home.menu.balanceActualization',
      icon: 'add_box',
      permission: Permission.updateBalance,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.BALANCE_ACTUALIZATION],
      keywords: ['bilanz', 'aktualisieren']
    }, {
      label: 'home.menu.editRF2Cycle',
      icon: 'account_balance',
      permission: Permission.editRaceCycle,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CYCLE_RF2],
      keywords: ['race', 'edit', 'smart', 'cycle']
    }, {
      label: 'home.menu.editBB1Cycle',
      icon: 'account_balance',
      permission: Permission.editBingoCycle,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CYCLE_BB1],
      keywords: ['bet', 'edit', 'keno', 'cycle']
    }, {
      label: 'home.menu.editPB1Cycle',
      icon: 'account_balance',
      permission: Permission.editGameCycle,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.CYCLE_PB1],
      keywords: ['bet', 'edit', 'poker', 'cycle']
    }, {
      label: 'home.menu.virtualMode',
      icon: 'cached',
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.VIRTUAL_MODE],
      permission: Permission.seeVirtualMode,
      keywords: ['virtual', 'mode']
    }, {
      label: 'home.menu.groupManagement',
      icon: 'account_balance',
      permission: Permission.editAdvancedAdminSettings,
      link: [SystemConfig.Routes.HOME, SystemConfig.Routes.HomeMenu.GROUP_MANAGEMENT],
      keywords: ['management', 'group']
    }];
  }
}
