import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {SessionService} from '../../services/session.service';
import {map} from 'rxjs/operators';
import {NotificationEntry} from './notificationEntry';
import {SystemConfig} from '../../config/system-config';

@Injectable({
  providedIn: 'root'
})
export class NotificationEditorService {

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetchInbox() {
    return this.httpService.get(SystemConfig.Endpoints.NOTIFICATION_EDITOR)
      .pipe(map((res: { notifications: NotificationEntry[] }) => {
        return res.notifications.map(notification => {
          try {
            notification.subject = JSON.parse(notification.subject as any);
            notification.body = JSON.parse(notification.body as any);
            if (notification.body?.components) {
              notification.body.components.sort((a, b) => a.order - b.order);
            }
            return notification;
          } catch (e) {
            console.error('Error parsing notification', notification);
            return null;
          }
        }).filter(n => !!n);
      }));
  }

  saveInbox(entry: NotificationEntry) {
    return this.httpService.post(entry.customerNotificationID
      ? SystemConfig.Endpoints.NOTIFICATION_EDITOR_UPDATE : SystemConfig.Endpoints.NOTIFICATION_EDITOR_ADD, {
      ...entry,
      subject: JSON.stringify(entry.subject),
      body: JSON.stringify(entry.body)
    });
  }

  loadNotification(nId: number) {
    return this.httpService.get(SystemConfig.Endpoints.NOTIFICATION_EDITOR_SINGLE + nId)
      .pipe(map((notification: NotificationEntry) => {
        notification.subject = JSON.parse(notification.subject as any);
        notification.body = JSON.parse(notification.body as any);
        if (notification.body?.components) {
          notification.body.components.sort((a, b) => a.order - b.order);
        }
        return notification;
      }));
  }
}
