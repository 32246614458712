import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, DecimalPipe} from '@angular/common';

import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './home.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {TicketsComponent} from './tickets/tickets.component';
import {RouterModule} from '@angular/router';
import {AgGridModule} from 'ag-grid-angular';
import {SearchFormComponent} from '../search-form/search-form.component';
import {MaterialImportsModule} from '../../material-imports.module';
import {SharedModule} from '../../shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicFormModule} from '../dynamic-form/dynamic-form.module';
import {CashbookComponent} from './cashbook/cashbook.component';
import {BalanceComponent} from './balance/balance.component';
import {BalanceMonthComponent} from './balance-month/balance-month.component';
import {TicketListModule} from '../ticket-list/ticket-list.module';
import {TerminalCashComponent} from './terminal-cash/terminal-cash.component';
import {TicketSearchComponent} from './ticket-search/ticket-search.component';
import {CashTicketOverviewComponent} from './cash-ticket-overview/cash-ticket-overview.component';
import {TicketDetailModule} from '../ticket-detail/ticket-detail.module';
import {StationOverviewComponent} from './station-overview/station-overview.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {CurrenciesComponent} from './currencies/currencies.component';
import {ColsModule} from '../cols/cols.module';
import {TicketListStationsComponent} from './ticket-list-stations/ticket-list-stations.component';
import {HoldOverviewComponent} from './hold-overview/hold-overview.component';
import {GroupJackpotsComponent} from './group-jackpots/group-jackpots.component';
import {ButtonsModule} from '../buttons/buttons.module';
import {UserManagementComponent} from './user-management/user-management.component';
import {RoleManagementComponent} from './role-management/role-management.component';
import { JackpotOverviewComponent } from './jackpot-overview/jackpot-overview.component';
import { BalanceActualizationComponent } from './balance-actualization/balance-actualization.component';
import { ResultsRf2Component } from './results-rf2/results-rf2.component';
import {ResultsBb1Component} from './results-bb1/results-bb1.component';
import {ResultsPb1Component} from './results-pb1/results-pb1.component';
import {CycleBb1Component} from './cycle-bb1/cycle-bb1.component';
import {CyclePb1Component} from './cycle-pb1/cycle-pb1.component';
import {CycleRf2Component} from './cycle-rf2/cycle-rf2.component';
import {WebshopHoldOverviewComponent} from './webshop-hold-overview/webshop-hold-overview.component';
import {ThirdPartyBalanceComponent} from './third-party-balance/third-party-balance.component';
import {CashTimelineComponent} from './cash-timeline/cash-timeline.component';
import {CashbookControllingComponent} from './cashbook-controlling/cashbook-controlling.component';
import {UaTimelineComponent} from './ua-timeline/ua-timeline.component';
import {BalanceCashTotalComponent} from './balance-cash-total/balance-cash-total.component';
import {NumbersHoldOverviewComponent} from './numbers-hold-overview/numbers-hold-overview.component';
import {CustomerInfoComponent} from './customer-info/customer-info.component';
import {TurnoverComponent} from './turnover/turnover.component';
import {PokerHoldOverviewComponent} from './poker-hold-overview/poker-hold-overview.component';
import {LiveHoldOverviewComponent} from './live-hold-overview/live-hold-overview.component';
import {BalanceLivesportsComponent} from './balance-livesports/balance-livesports.component';
import {VirtualModeComponent} from './virtual-mode/virtual-mode.component';
import {VirtualModeLogoutComponent} from './virtual-mode-logout/virtual-mode-logout.component';
import {BetLimitsComponent} from './bet-limits/bet-limits.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {BalanceFranchiserComponent} from './balance-franchiser/balance-franchiser.component';
import {BalanceGroupComponent} from './balance-group/balance-group.component';
import {HoldOverviewFranchiserComponent} from './hold-overview-franchiser/hold-overview-franchiser.component';
import {ActionButtonCellRenderer} from './cash-timeline/action-button-cell-renderer/action-button-cell-renderer.component';
import {CustomerHoldComponent} from './customer-hold/customer-hold.component';
import { CashErrorsComponent } from './cash-errors/cash-errors.component';
import {NgChartsModule} from 'ng2-charts';
import {RecyclerTimelineComponent} from './recycler-timeline/recycler-timeline.component';
import { GroupManagementComponent } from './group-management/group-management.component';
import { CustomerBalanceWeekComponent } from './customer-balance-week/customer-balance-week.component';
import {NotificationEditorComponent} from './notification-editor/notification-editor.component';
import {MarkdownModule} from 'ngx-markdown';
import { InkassoComponent } from './inkasso/inkasso.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HomeRoutingModule,
    AgGridModule,
    MaterialImportsModule,
    FormsModule,
    TicketDetailModule,
    ReactiveFormsModule,
    DynamicFormModule,
    SharedModule,
    TicketListModule,
    ColsModule,
    ButtonsModule,
    MatSlideToggleModule,
    NgChartsModule,
    MarkdownModule
  ],
  declarations: [
    HomeComponent,
    DashboardComponent,
    TurnoverComponent,
    TicketsComponent,
    ChangePasswordComponent,
    CustomerInfoComponent,
    SearchFormComponent,
    CashbookComponent,
    BalanceComponent,
    BalanceFranchiserComponent,
    BalanceMonthComponent,
    BetLimitsComponent,
    TerminalCashComponent,
    TicketSearchComponent,
    CashTicketOverviewComponent,
    StationOverviewComponent,
    CurrenciesComponent,
    TicketListStationsComponent,
    HoldOverviewComponent,
    GroupJackpotsComponent,
    UserManagementComponent,
    RoleManagementComponent,
    CashTimelineComponent,
    RecyclerTimelineComponent,
    CashbookControllingComponent,
    JackpotOverviewComponent,
    NumbersHoldOverviewComponent,
    PokerHoldOverviewComponent,
    LiveHoldOverviewComponent,
    BalanceActualizationComponent,
    HoldOverviewFranchiserComponent,
    ResultsRf2Component,
    UaTimelineComponent,
    BalanceCashTotalComponent,
    ResultsBb1Component,
    ResultsPb1Component,
    CycleBb1Component,
    ThirdPartyBalanceComponent,
    CyclePb1Component,
    WebshopHoldOverviewComponent,
    CycleRf2Component,
    BalanceLivesportsComponent,
    VirtualModeComponent,
    VirtualModeLogoutComponent,
    NotificationEditorComponent,
    CustomerHoldComponent,
    ActionButtonCellRenderer,
    CashErrorsComponent,
    BalanceGroupComponent,
    GroupManagementComponent,
    CustomerBalanceWeekComponent,
    InkassoComponent
  ],
  providers: [DatePipe, DecimalPipe]
})
export class HomeModule {
}
