import { Component, OnInit, ViewChild } from '@angular/core';
import { ColsComponent } from '../../cols/cols.component';
import { QuestionContainer } from '../../domain/question-container';
import { DropdownQuestion } from '../../domain/question-dropdown';
import { SessionService } from '../../services/session.service';
import { TranslationService } from '../../services/translation.service';
import { DropdownValue } from '../../dynamic-form/dropdown-value';
import { RoleManagementService } from './role-management.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Permission } from '../../domain/permission';
import { map } from 'rxjs/operators';
import { ERROR_SNACK_CONFIG, mapMap, SUCCESS_SNACK_CONFIG } from '../../../utils';
import { TextboxQuestion } from '../../domain/question-textbox';
import { CheckboxQuestion } from '../../domain/question-checkbox';
import { FormUtils } from '../../utils/form-utils';

@Component({
  selector: 'vit-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss']
})
export class RoleManagementComponent implements OnInit {

  @ViewChild(ColsComponent, { static: true })
  colsComponent: ColsComponent;

  selectionQuestions = [];
  roleQuestions = [];
  roles = [];
  questions = [];
  roleDto = null;
  isNew = false;
  loading = false;

  Permission = Permission;

  constructor(public sessionService: SessionService,
    public translationService: TranslationService,
    public roleManagementService: RoleManagementService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.reset();
  }

  reset() {
    this.roleDto = null;
    this.isNew = false;
    this.roleManagementService.fetchRoles().subscribe((roles) => {
      this.roles = roles;
      this.setSelectionQuestions();
      this.questions = this.selectionQuestions;
    });
  }

  private setSelectionQuestions() {
    this.selectionQuestions = [new QuestionContainer('roleManagement.header.roles', [
      new DropdownQuestion({
        key: 'roleID',
        label: 'roleManagement.roleID',
        value: null,
        clear: true,
        options: this.roles.map(role => ({ key: role.roleID.toString(), value: role.roleName })),
        onChange: this.onRoleSelected.bind(this)
      })])];

    }

  private setRoleQuestions() {
    const role = new QuestionContainer('roleManagement.header.role', [
      new TextboxQuestion({
        key: 'roleName',
        label: 'roleManagement.roleName',
        value: this.roleDto.role.roleName,
        required: this.isNew,
        editPermission: this.isNew ? null : Permission.notAllowed
      }),
      new TextboxQuestion({
        key: 'description',
        label: 'roleManagement.description',
        required: this.isNew,
        value: this.roleDto.role.description,
      })
    ]);

    const permissions = new QuestionContainer('roleManagement.header.permissions', []);

    permissions.questions = this.roleDto.permissionSelectionList.map(permissionDto => new CheckboxQuestion({
      key: permissionDto.permission.permissionID,
      label: permissionDto.permission.description + ' (' + permissionDto.permission.permissionName + ')',
      value: permissionDto.isSelected
    }));

    this.roleQuestions = [role, permissions];

    this.questions = this.roleQuestions;
  }

  new() {
    this.roleDto = {
      role: {
        'roleID': 0,
        'roleName': 'Neue Rolle',
        'description': 'Beschreibung der neuen Rolle',
        'hierarchyID': 0,
        'userID': null,
        'lastChange': null,
      },
      'permissionSelectionList': []
    };
    this.isNew = true;
    this.setRoleQuestions();
  }

  save() {
    const roleForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'roleManagement.header.role');
    const permissionsForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'roleManagement.header.permissions');

    if (!roleForm['roleName']) {
      this.snackBar.open(this.translationService.translate('roleManagement.nameMissing'), null, ERROR_SNACK_CONFIG);
      return;
    }
    Object.assign(this.roleDto.role, roleForm);

    this.roleDto.permissionSelectionList.forEach(permissionDto => {
      permissionDto.isSelected = permissionsForm[permissionDto.permission.permissionID];
    });

    const result = this.isNew ? this.roleManagementService.insertRole(this.roleDto.role) : this.roleManagementService.updateRole(this.roleDto);
    result.subscribe({
      next: (response: any) => {
        this.snackBar.open(
          this.translationService.translate("snackBar.saved"),
          null,
          SUCCESS_SNACK_CONFIG,
        );
        this.onRoleSelected(
          this.isNew ? response.role.roleID : this.roleDto.role.roleID,
        );
      },
      error: () =>
        this.snackBar.open(
          this.translationService.translate("snackBar.error"),
          null,
          ERROR_SNACK_CONFIG,
        ),
    });
  }

  private onRoleSelected(id) {
    if (id) {
      this.roleManagementService.fetchRole(id).subscribe((userDto) => {
        this.roleDto = userDto;
        this.isNew = false;
        this.setRoleQuestions();
      });
    }
  }

}
