import { Injectable } from "@angular/core";
import { HttpService } from "../../services/http.service";
import { Observable } from "rxjs";
import { QueryFilter } from "../../domain/query-filter";
import { SystemConfig } from "../../config/system-config";

@Injectable({
  providedIn: "root",
})
export class InkassoService {
  constructor(private httpService: HttpService) {}

  fetchInkassoList(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.INKASSO, query);
  }

}
