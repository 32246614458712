import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {HoldOverviewService} from '../services/hold-overview.service';
import {GameNumber} from '../domain/game-number';
import {StationUtils} from '../utils/station-utils';
import {TreeNodeType} from '../domain/tree-node-type';
import {Permission} from '../domain/permission';
import {QueryFilter} from '../domain/query-filter';
import {clone} from '../utils/basic-utils';
import {enrichByStationId, getContextMenuItems, isNumber, loadColumnVisibility, storeColumnVisibility} from '../../utils';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {TranslationService} from '../services/translation.service';
import {NotificationService} from '../notification.service';
import {CashTicketOverviewService} from '../services/cash-ticket-overview.service';
import {SessionService} from '../services/session.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DatePipe, DecimalPipe} from '@angular/common';
import {GridReadyEvent} from 'ag-grid-community';
import {HttpService} from '../services/http.service';

@Component({
  selector: 'vit-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss']
})
export class TicketDetailComponent implements OnInit, OnDestroy, ICellRendererAngularComp {


  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  storeColumnVisibilityFn = storeColumnVisibility;
  columnDefs;
  gridOptions;
  rowData;
  detailParams;
  query;

  params;
  loading;

  @HostBinding('class.not-fixed')
  get shouldLabelFloat() {
    return this.noFixedButtons;
  }

  @HostBinding('class.is-cash-ticket')
  get isMobileClass() {
    return this.gameNumber === 0;
  }

  @Input()
  gameNumber: GameNumber;
  @Input()
  ticketData: any;
  @Input()
  noFixedButtons = false;

  constructor(private holdOverviewService: HoldOverviewService,
              public sessionService: SessionService,
              public translationService: TranslationService,
              public dialog: MatDialog,
              public httpService: HttpService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe,
              public cashTicketOverviewService: CashTicketOverviewService,
              public notificationService: NotificationService) {
    this.columnDefs = this.generateColumnDefs();
    this.detailParams = this.generateDetailParams();
    this.gridOptions = this.generateGridOptions();
  }

  ngOnInit() {
    console.log('ngOnInit', this.ticketData, this.gameNumber);
    if (this.ticketData && this.gameNumber) {
      this.params = {
        data: {
          gameNumber: this.gameNumber,
          number: (this.ticketData && this.ticketData.ticket) ? this.ticketData.ticket.number : null
        }
      };
    }

    if (this.gameNumber === 0) {
      this.query = {
        'franchiserID': null,
        'stationID': this.ticketData.stationId,
        'countryCode': null,
        'dateFrom': null,
        'dateTo': null,
        'ignorePB1': false,
        'ignoreRF2': false,
        'ignoreBB1': false,
        'ignoreLB2': false,
        'ignoreShop': false,
        'ignoreTerminal': false,
        'ignoreTerminalShop': false,
        'ignoreBridge': false,
        'ignoreWebshop': false,
        'ignoreCashier': false,
        'minStake': null,
        'minProfit': null,
        'ignoreSubFranchiser': false,
        'flag': false,
        'userID': null,
        'ignoreTest': false,
        'minTickets': null,
        'intoEuro': false,
        'customerID': null,
        'userAccountID': null,
        'eventID': 0,
        'ticketStatus': 0,
        'ticketNumber': +this.ticketData.ticket.number,
        'cashbookNumber': 0,
        'raceCycleID': null,
        'bingoCycleID': null,
        'groupID': null
      };
    }
  }

  ngOnDestroy() {
  }

  onGridReady(event: GridReadyEvent) {
    console.log('ready', event);
    loadColumnVisibility('ticketDetail', event);
  }

  // called on init
  agInit(params: any): void {
    this.params = params;
    if (!this.loading) {
      this.loading = true;
      const query = {
        'queryMode': null,
        'franchiserID': null,
        'stationID': null,
        'countryCode': null,
        'dateFrom': null,
        'dateTo': null,
        'ignorePB1': false,
        'ignoreRF2': false,
        'ignoreBB1': false,
        'ignoreLB2': false,
        'ignoreShop': false,
        'ignoreTerminal': false,
        'ignoreTerminalShop': false,
        'ignoreBridge': false,
        'ignoreWebshop': false,
        'ignoreCashier': false,
        'minStake': null,
        'minProfit': null,
        'ignoreSubFranchiser': false,
        'flag': false,
        'userID': null,
        'ignoreTest': false,
        'minTickets': null,
        'intoEuro': params.data && params.data.queryData ? !!params.data.queryData.intoEuro : false,
        'customerID': null,
        'userAccountID': null,
        'ignoreOnline': false,
        'ignoreOffline': false,
        'ignoreLocked': false,
        'ignoreUnlocked': false,
        'eventID': 0,
        'ticketStatus': 0,
        'ticketNumber': params.data.number,
        'cashbookNumber': 0,
        'raceCycleID': null,
        'bingoCycleID': null,
        'groupID': null
      };
      this.query = query;
      this.gameNumber = params.data.gameNumber as GameNumber;
      this.ticketData = null;
      console.log('agInit', this.ticketData, this.gameNumber);
      this.holdOverviewService.fetchTicket(query, this.gameNumber).subscribe({
        next: (ticketData) => {
          this.ticketData = ticketData;
          this.loading = false;
        },
        error: () => (this.loading = false),
      });
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('cashTicketOverview.stationType'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationType',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.stationType, false, false, false);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('cashTicketOverview.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('cashTicketOverview.stationID')
      },
      {
        headerName: this.translationService.translate('cashTicketOverview.stationName'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationName',
        width: TicketDetailComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('cashTicketOverview.stationName')
      },
      {
        headerName: this.translationService.translate('cashTicketOverview.num'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'num',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: TicketDetailComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashTicketOverview.num')
      },
      {
        headerName: this.translationService.translate('cashTicketOverview.valueSum'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'valueSum',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: TicketDetailComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashTicketOverview.valueSum')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      rowClassRules: {
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      }
    };
  }

  generateDetailParams() {
    return {
      detailGridOptions: {
        enableSorting: true,
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        enableFilter: true,
        detailRowHeight: 300,
        enableColResize: true,
        columnDefs: [
          {
            headerName: this.translationService.translate('cashTicketOverview.number'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'number',
            width: TicketDetailComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashTicketOverview.number')
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.value'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'value',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: TicketDetailComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('cashTicketOverview.value')
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.disburseTypeID'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'disburseTypeID',
            valueFormatter: this.typeGenerator.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: TicketDetailComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashTicketOverview.disburseTypeID')
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.inDate'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'inDate',
            valueFormatter: this.dateFormatter.bind(this),
            width: TicketDetailComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashTicketOverview.inDate')
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.outDate'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'outDate',
            valueFormatter: this.dateFormatter.bind(this),
            width: TicketDetailComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashTicketOverview.outDate')
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.outStationID'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'outStationID',
            width: TicketDetailComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashTicketOverview.outStationID')
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.voucherCode'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'voucherCode',
            cellClass: 'text-align-right',
            width: TicketDetailComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashTicketOverview.voucherCode')
          },
          this.sessionService.hasPermission(Permission.stationOwnerPin) ? {
            headerName: this.translationService.translate('cashTicketOverview.redeemManually'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'number',
            width: TicketDetailComponent.COLUMN_WIDTH_LARGE,
            cellRenderer: (params) => {
              const eDiv = document.createElement('div');
              eDiv.innerHTML = '<span class="my-css-class"><button mat-stroked-button class="btn-redeem-manually">'
                + this.translationService.translate('cashTicketOverview.redeemManuallyBtn') + '</button></span>';
              const eButton = eDiv.querySelectorAll('.btn-redeem-manually')[0];
              eButton.addEventListener('click', () => this.redeemManually(params));
              const isAllowed = params.data.disburseTypeID === 1 && params.data.outDate === null;
              return isAllowed ? eDiv : '';
            }
          } : null,
          this.sessionService.hasPermission(Permission.stationOwnerPin) ? {
            headerName: this.translationService.translate('cashTicketOverview.redeemAndPayinManually'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'number',
            width: 180,
            cellRenderer: (params) => {
              const eDiv = document.createElement('div');
              eDiv.innerHTML = '<span class="my-css-class"><button mat-stroked-button class="btn-redeem-manually">'
                + this.translationService.translate('cashTicketOverview.redeemAndPayinManuallyBtn') + '</button></span>';
              const eButton = eDiv.querySelectorAll('.btn-redeem-manually')[0];
              eButton.addEventListener('click', () => this.redeemAndPayinManually(params));
              const isAllowed = params.data.disburseTypeID === 1 && params.data.outDate === null;
              return isAllowed ? eDiv : '';
            }
          } : null
        ].filter(el => !!el),
        onCellClicked: (params) => {
          params.node.setExpanded(!params.node.expanded);
        }
      },
      getDetailRowData: (params) => {
        const query: QueryFilter = clone(this.query);
        query.stationID = params.data.stationID;
        this.cashTicketOverviewService.fetchCashTickets(query).subscribe(data => {
          params.successCallback(enrichByStationId(data, params.data.stationID));
        });
      }
    };
  }

  redeemManually(params) {
    this.dialog.open(ConfirmDialogComponent).afterClosed().subscribe(result => {
      if (result) {
        const query = clone(this.query) as QueryFilter;
        query.ticketNumber = params.value;
        this.cashTicketOverviewService.redeemManually(query).subscribe({
          next: () => {
            this.notificationService.showSaved();
          },
          error: () => {
            this.notificationService.showError();
          },
        });
      }
    });
  }

  redeemAndPayinManually(params) {
    this.dialog.open(ConfirmDialogComponent).afterClosed().subscribe(result => {
      if (result) {
        const query = clone(this.query) as QueryFilter;
        query.ticketNumber = params.value;
        this.cashTicketOverviewService.redeemAndPayinManually(query).subscribe({
          next: () => {
            this.notificationService.showSaved();
          },
          error: () => {
            this.notificationService.showError();
          },
        });
      }
    });
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  typeGenerator(x: any) {
    let text = '';
    if (x.value === 1) {
      if (x.data.outDate != null) {
        text = this.translationService.translate('cashTicketOverview.cashTicket');
      } else {
        text = this.translationService.translate('cashTicketOverview.cashTicketOpen');
      }
    } else if (x.data.voucherCode != null && x.data.userAccountID != null) {
      text = this.translationService.translateArgs('cashTicketOverview.voucherUserAccountId', x.data.userAccountID);
    } else if (x.data.voucherCode != null && x.data.userAccountID == null) {
      text = this.translationService.translate('cashTicketOverview.voucher');
    } else {
      text = 'unknown';
    }
    return text;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }
}
