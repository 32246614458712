import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../services/user.service';
import {fadeInOut} from '../animations/fade-in-out';
import {Router} from '@angular/router';
import {SystemConfig} from '../config/system-config';
import {StartupService} from '../services/startup.service';
import {CustomerTreeService} from '../services/customer-tree.service';

@Component({
  selector: 'vit-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInOut]
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  hide = true;
  error: boolean;
  submitted = false;

  constructor(public userService: UserService,
              public fb: UntypedFormBuilder,
              public router: Router,
              public customerTreeService: CustomerTreeService,
              public startupService: StartupService) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.customerTreeService.tree.next(null);
    localStorage.removeItem(SystemConfig.LocalStorageConstants.VIRTUAL_USER);
  }

  login() {
    if (this.submitted) {
      return;
    }
    this.submitted = true;
    this.error = false;
    this.userService
      .login(this.loginForm.value.username, this.loginForm.value.password)
      .subscribe({
        next: () => {
          localStorage.setItem(
            SystemConfig.LocalStorageConstants.SESSION_REFRESH,
            "" + new Date().getTime(),
          );
          location.reload();
        },
        error: (error) => {
          console.error("login error", error);
          this.error = true;
          this.submitted = false;
        },
      });
  }

  close() {
    this.error = false;
  }

}
