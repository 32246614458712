import {Component, NgZone, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ColsComponent} from '../../cols/cols.component';
import {QuestionContainer} from '../../domain/question-container';
import {Jackpot} from '../../domain/jackpot';
import {JackpotService} from '../../services/jackpot.service';
import {DropdownQuestion} from '../../domain/question-dropdown';
import {ERROR_SNACK_CONFIG, sortAlphabetically, SUCCESS_SNACK_CONFIG} from '../../../utils';
import {SessionService} from '../../services/session.service';
import {TranslationService} from '../../services/translation.service';
import {GameNumber} from '../../domain/game-number';
import {TextboxQuestion} from '../../domain/question-textbox';
import {CheckboxQuestion} from '../../domain/question-checkbox';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {FormUtils} from '../../utils/form-utils';
import {DynamicFormComponent} from '../../dynamic-form/dynamic-form.component';
import {UntypedFormGroup} from '@angular/forms';
import {CustomerTreeService} from '../../services/customer-tree.service';

@Component({
  selector: 'vit-group-jackpots',
  templateUrl: './group-jackpots.component.html',
  styleUrls: ['./group-jackpots.component.scss']
})
export class GroupJackpotsComponent implements OnInit {

  loading: boolean;
  @ViewChild(ColsComponent, { static: false })
  colsComponent: ColsComponent;
  @ViewChildren(DynamicFormComponent)
  forms: QueryList<DynamicFormComponent>;

  franchisers: any[] = [];
  selectedJackpot: Jackpot;
  jackpots: Jackpot[];
  questions: QuestionContainer[] = [];
  isNew = false;

  constructor(public jackpotService: JackpotService,
              public snackBar: MatSnackBar,
              public sessionService: SessionService,
              public ngZone: NgZone,
              public customerTreeService: CustomerTreeService,
              public translationService: TranslationService) {
  }

  ngOnInit() {
    this.loading = true;
    this.customerTreeService.tree.subscribe(node => {
      this.franchisers = [];
      this.franchisers.push(...this.sessionService.franchisers.map(f => {
        return {
          'key': f.node.id.toString(), 'value': f.node.label
        };
      }));
      // this.franchisers.unshift({value: this.translationService.translate('searchForm.all'), key: '-1'});
      sortAlphabetically(this.franchisers, 'value');
      this.jackpotService.fetchGroupJackpots().subscribe(jps => this.initForm(jps));
    });
  }

  reset() {
    this.initForm(this.jackpots);
  }

  getFormValue(): Jackpot {
    const form: any = FormUtils.getFormValues(this.forms, 'groupJackpots.header');
    return {
      'jackpotID': +form['jackpotID'],
      'franchiserID': +form['franchiserID'],
      'applicationID': +form['games'],
      'value': +form['value'],
      'minInitialValue': +form['minInitialValue'],
      'maxInitialValue': +form['maxInitialValue'],
      'minValue': +form['minValue'],
      'maxValue': +form['maxValue'],
      'hitValue': this.selectedJackpot.hitValue,
      'lastHitTime': this.selectedJackpot.lastHitTime,
      'lastHitStation': this.selectedJackpot.lastHitStation,
      'lastHitValue': this.selectedJackpot.lastHitValue,
      'name': form['name'],
      'autoRaise': form['autoRaise'],
    };
  }

  save() {
    const jackpot = this.getFormValue();
    if (jackpot.jackpotID === -1) {
      this.jackpotService.insertGroupJackpot(jackpot).subscribe({
        next: () => {
          this.snackBar.open(
            this.translationService.translate("snackBar.saved"),
            null,
            SUCCESS_SNACK_CONFIG,
          );
          this.jackpotService
            .fetchGroupJackpots()
            .subscribe((jps) => this.initForm(jps));
        },
        error: () => {
          this.snackBar.open(
            this.translationService.translate("snackBar.error"),
            null,
            ERROR_SNACK_CONFIG,
          );
        },
      });
    } else {
      this.jackpotService.updateGroupJackpot(jackpot).subscribe({
        next: () => {
          this.snackBar.open(
            this.translationService.translate("snackBar.saved"),
            null,
            SUCCESS_SNACK_CONFIG,
          );
        },
        error: () => {
          this.snackBar.open(
            this.translationService.translate("snackBar.error"),
            null,
            ERROR_SNACK_CONFIG,
          );
        },
      });
    }
  }

  delete() {
    const jackpot = this.getFormValue();
    this.jackpotService.deleteGroupJackpot(jackpot).subscribe({
      next: () => {
        this.jackpotService
          .fetchGroupJackpots()
          .subscribe((jps) => this.initForm(jps));
        this.snackBar.open(
          this.translationService.translate("snackBar.saved"),
          null,
          SUCCESS_SNACK_CONFIG,
        );
      },
      error: () => {
        this.snackBar.open(
          this.translationService.translate("snackBar.error"),
          null,
          ERROR_SNACK_CONFIG,
        );
      },
    });
  }


  init() {
    const jackpot = this.getFormValue();
    this.jackpotService.initGroupJackpot(jackpot).subscribe({
      next: (jp) => {
        this.selectedJackpot = jp;
        this.setFormValues();
        this.snackBar.open(
          this.translationService.translate("snackBar.saved"),
          null,
          SUCCESS_SNACK_CONFIG,
        );
      },
      error: () => {
        this.snackBar.open(
          this.translationService.translate("snackBar.error"),
          null,
          ERROR_SNACK_CONFIG,
        );
      },
    });
  }

  initForm(jackpots: Jackpot[]) {
    this.loading = false;
    this.jackpots = jackpots;

    this.jackpots.unshift({
      'jackpotID': -1,
      'franchiserID': this.sessionService.user.franchiserID,
      'applicationID': GameNumber.POKERBET,
      'value': 0,
      'minInitialValue': 10,
      'maxInitialValue': 20,
      'minValue': 30,
      'maxValue': 40,
      'hitValue': 0,
      'lastHitTime': null,
      'lastHitStation': null,
      'lastHitValue': null,
      'name': '',
      'autoRaise': false
    });

    this.selectedJackpot = this.jackpots.length > 0 ? this.jackpots[0] : null;
    this.setFormValues();
  }

  private setFormValues() {
    this.questions = [];
    const games = [GameNumber.POKERBET, GameNumber.SMARTRACE, GameNumber.KENOBET];
    this.questions.push(new QuestionContainer('groupJackpots.header', [
      new DropdownQuestion({
        key: 'jackpotID',
        label: 'groupJackpots.jackpotID',
        value: this.selectedJackpot.jackpotID.toString(),
        options: Array.from(this.jackpots.map((jp) => ({
          key: jp.jackpotID.toString(),
          value: jp.name ? jp.name : '--- neuer Jackpot ---'
        }))),
        onChange: this.jackpotChanged.bind(this)
      }),
      new DropdownQuestion({
        key: 'franchiserID',
        label: 'groupJackpots.franchiserID',
        value: this.selectedJackpot.franchiserID.toString(),
        options: this.franchisers
      }),
      new DropdownQuestion({
        key: 'games',
        label: 'groupJackpots.games',
        options: games.map((game) => ({key: game.toString(), value: this.translationService.translate('groupJackpots.game.' + game)})),
        value: games[0].toString()
      }),
      new TextboxQuestion({
        key: 'name',
        label: 'groupJackpots.name',
        value: this.selectedJackpot.name,
        required: true
      }),
      new TextboxQuestion({
        key: 'value',
        label: 'groupJackpots.value',
        type: 'number',
        disabled: true,
        value: this.selectedJackpot.value
      }),
      new TextboxQuestion({
        key: 'minInitialValue',
        type: 'number',
        label: 'groupJackpots.minInitialValue',
        value: this.selectedJackpot.minInitialValue
      }),
      new TextboxQuestion({
        key: 'maxInitialValue',
        type: 'number',
        label: 'groupJackpots.maxInitialValue',
        value: this.selectedJackpot.maxInitialValue
      }),
      new TextboxQuestion({
        key: 'minValue',
        type: 'number',
        label: 'groupJackpots.minValue',
        value: this.selectedJackpot.minValue
      }),
      new TextboxQuestion({
        key: 'maxValue',
        type: 'number',
        label: 'groupJackpots.maxValue',
        value: this.selectedJackpot.maxValue
      }),
      new CheckboxQuestion({
        key: 'autoRaise',
        type: 'number',
        disabled: true,
        label: 'groupJackpots.autoRaise',
        value: this.selectedJackpot.autoRaise
      }),
      new TextboxQuestion({
        key: 'lastHitTime',
        label: 'groupJackpots.lastHitTime',
        disabled: true,
        value: this.selectedJackpot.lastHitTime
      }),
      new TextboxQuestion({
        key: 'lastHitStation',
        label: 'groupJackpots.lastHitStation',
        disabled: true,
        value: this.selectedJackpot.lastHitStation
      }),
      new TextboxQuestion({
        key: 'lastHitValue',
        label: 'groupJackpots.lastHitValue',
        disabled: true,
        type: 'number',
        value: this.selectedJackpot.lastHitValue
      }),

    ]));
  }

  private jackpotChanged(jackpotId: string) {
    this.selectedJackpot = this.jackpots.find(jp => jp.jackpotID === +jackpotId);
    const form: UntypedFormGroup = FormUtils.getForm(this.forms, 'groupJackpots.header');

    form.reset({
      'jackpotID': this.selectedJackpot.jackpotID.toString(),
      'franchiserID': this.selectedJackpot.franchiserID.toString(),
      'games': this.selectedJackpot.applicationID.toString(),
      'value': this.selectedJackpot.value,
      'minInitialValue': this.selectedJackpot.minInitialValue,
      'maxInitialValue': this.selectedJackpot.maxInitialValue,
      'minValue': this.selectedJackpot.minValue,
      'maxValue': this.selectedJackpot.maxValue,
      'lastHitTime': this.selectedJackpot.lastHitTime,
      'lastHitStation': this.selectedJackpot.lastHitStation,
      'lastHitValue': this.selectedJackpot.lastHitValue,
      'name': this.selectedJackpot.name,
      'autoRaise': this.selectedJackpot.autoRaise
    });
  }

}
