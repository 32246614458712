import {Component, OnInit, ViewChild} from '@angular/core';
import {ColsComponent} from '../../cols/cols.component';
import {QuestionContainer} from '../../domain/question-container';
import {DropdownQuestion} from '../../domain/question-dropdown';
import {ERROR_SNACK_CONFIG, sortAlphabetically, SUCCESS_SNACK_CONFIG} from '../../../utils';
import {SessionService} from '../../services/session.service';
import {TranslationService} from '../../services/translation.service';
import {DropdownValue} from '../../dynamic-form/dropdown-value';
import {FormUtils} from '../../utils/form-utils';
import {UserManagementService} from './user-management.service';
import {TextboxQuestion} from '../../domain/question-textbox';
import {CheckboxQuestion} from '../../domain/question-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {Permission} from '../../domain/permission';
import {DatePipe} from '@angular/common';
import {CustomerTreeService} from '../../services/customer-tree.service';

@Component({
  selector: 'vit-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  @ViewChild(ColsComponent, { static: true })
  colsComponent: ColsComponent;

  selectionQuestions = [];
  userQuestions = [];
  franchisersAll: DropdownValue[] = [];
  franchisers: DropdownValue[] = [];
  selectedFranchiserID: string;
  questions = [];
  userDto = null;
  isNew = false;
  loading = false;
  newPassword = false;
  passwordsMatch = false;

  Permission = Permission;

  constructor(public sessionService: SessionService,
              public translationService: TranslationService,
              public userManagementService: UserManagementService,
              public datePipe: DatePipe,
              public customerTreeService: CustomerTreeService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.customerTreeService.tree.subscribe(node => {
      if (node) {
        this.franchisersAll.push(...this.sessionService.franchisers.map(f => {
          return {
            'key': f.node.id.toString(), 'value': f.node.label
          };
        }));
        this.franchisers = JSON.parse(JSON.stringify(this.franchisersAll));
        sortAlphabetically(this.franchisersAll, 'value');
        this.franchisersAll.unshift({value: this.translationService.translate('searchForm.all'), key: '0'});
        this.reset();
      }
    });
  }

  reset() {
    this.userDto = null;
    this.isNew = false;
    this.userManagementService.fetchUsers(+this.franchisersAll[0].key).subscribe((options) => {
      this.setSelectionQuestions(options);
      this.questions = this.selectionQuestions;
    });
  }

  private setSelectionQuestions(options) {
    this.selectionQuestions = [new QuestionContainer('userManagement.header.users', [
      new DropdownQuestion({
        key: 'franchiserID',
        label: 'userManagement.franchiserID',
        value: '0',
        clear: true,
        clearTo: '0',
        options: this.franchisersAll,
        onChange: this.franchiserChanged.bind(this)
      }),
      new DropdownQuestion({
        key: 'user',
        label: 'userManagement.user',
        value: null,
        clear: true,
        onChange: this.onUserSelected.bind(this),
        options: options
      })])];
  }

  private setUserQuestions() {
    const user = new QuestionContainer('userManagement.header.user', [
      new TextboxQuestion({
        key: 'username',
        label: 'userManagement.username',
        value: this.userDto.user.username,
      }),
      new TextboxQuestion({
        key: 'displayName',
        label: 'userManagement.displayName',
        value: this.userDto.user.displayName,
      }),
      new TextboxQuestion({
        key: 'userID',
        label: 'userManagement.userID',
        value: this.userDto.user.userID,
        disabled: true
      }),
      new TextboxQuestion({
        key: 'lastLoginBackend',
        label: 'userManagement.lastLoginBackend',
        value: this.datePipe.transform(this.userDto.user.lastLoginBackend, 'medium'),
        disabled: true
      }),
      new DropdownQuestion({
        key: 'franchiserID',
        label: 'userManagement.franchiserID',
        value: this.userDto.user.franchiserID.toString(),
        options: this.franchisers,
        readonly: this.userDto.user.userID === this.sessionService.user.userID
      }),
      new TextboxQuestion({
        key: 'password',
        label: 'userManagement.password',
        value: '',
        type: 'password',
        onChange: this.onPasswordChange.bind(this),
      }),
      new TextboxQuestion({
        key: 'passwordRepeat',
        label: 'userManagement.passwordRepeat',
        value: '',
        type: 'password',
        onChange: this.onPasswordRepeatChange.bind(this),
      }),
      new CheckboxQuestion({
        key: 'locked',
        label: 'userManagement.locked',
        value: this.userDto.user.locked,
        readonly: !this.sessionService.hasPermission(Permission.lockWebUser) || this.userDto.user.userID === this.sessionService.user.userID
      }),
      new CheckboxQuestion({
        key: 'twoFactorAuthEnabled',
        label: 'userManagement.twoFactorAuthEnabled',
        value: this.userDto.user.twoFactorAuthEnabled,
      }),
      new CheckboxQuestion({
        key: 'stationAccessEnabled',
        label: 'userManagement.stationAccessEnabled',
        value: this.userDto.user.stationAccessEnabled,
        readonly: !this.sessionService.hasPermission(Permission.editAdvancedAdminSettings)
      }),
      new TextboxQuestion({
        key: 'stationPIN',
        label: 'userManagement.stationPIN',
        value: this.userDto.user.stationPIN
      }),
      new TextboxQuestion({
        key: 'email',
        label: 'userManagement.email',
        value: this.userDto.user.email
      }),
      new TextboxQuestion({
        type: 'number',
        key: 'inactivityTimeout',
        label: 'userManagement.inactivityTimeout',
        value: this.userDto.user.inactivityTimeout,
        viewPermission: Permission.editAdvancedAdminSettings
      }),
      new CheckboxQuestion({
        key: 'adminAccessEnabled',
        label: 'userManagement.adminAccessEnabled',
        value: this.userDto.user.adminAccessEnabled,
        viewPermission: Permission.editAdvancedAdminSettings,
        editPermission: this.sessionService.user.liveAdminEnabled ? null : Permission.notAllowed
      }),
      new CheckboxQuestion({
        key: 'backendAccessEnabled',
        label: 'userManagement.backendAccessEnabled',
        value: this.userDto.user.backendAccessEnabled,
        readonly: this.userDto.user.userID === this.sessionService.user.userID
      }),
      new CheckboxQuestion({
        key: 'userAccountAdminEnabled',
        label: 'userManagement.userAccountAdminEnabled',
        value: this.userDto.user.userAccountAdminEnabled,
        viewPermission: (this.sessionService.user.userAccountAdminEnabled
          || this.sessionService.hasPermission(Permission.editAdvancedAdminSettings)) ? null : Permission.notAllowed,
        editPermission: Permission.editUAACurrentCredit
      }),
      new CheckboxQuestion({
        key: 'sendSMSAllowed',
        label: 'userManagement.sendSMSAllowed',
        value: this.userDto.user.sendSMSAllowed,
        viewPermission: (this.sessionService.user.userAccountAdminEnabled
          || this.sessionService.hasPermission(Permission.editAdvancedAdminSettings)) ? null : Permission.notAllowed,
        editPermission: Permission.editAdvancedAdminSettings,
      }),
      new TextboxQuestion({
        type: 'number',
        key: 'userAccountAdminMaxCredit',
        label: 'userManagement.userAccountAdminMaxCredit',
        value: this.userDto.user.userAccountAdminMaxCredit,
        viewPermission: (this.sessionService.user.userAccountAdminEnabled
          || this.sessionService.hasPermission(Permission.editAdvancedAdminSettings)) ? null : Permission.notAllowed,
        editPermission: (this.sessionService.user.userAccountAdminEnabled
          && this.sessionService.hasPermission(Permission.editUAACurrentCredit)) ? null : Permission.notAllowed
      })
    ], this.userDto.isSessionAllowedToEditSettings ? null : Permission.notAllowed);

    const roles = new QuestionContainer('userManagement.header.roles', [],
      (this.userDto.isSessionAllowedToEditSettings && this.userDto.user.userID !== this.sessionService.user.userID)
        ? null : Permission.notAllowed);

    roles.questions = this.userDto.roleSelectionList.map(roleDto => new CheckboxQuestion({
      key: roleDto.role.roleID,
      tooltip: roleDto.role.description,
      label: roleDto.role.roleName,
      value: roleDto.isSelected
    }));

    this.userQuestions = [user, roles];

    this.questions = this.userQuestions;
  }

  new() {
    this.userManagementService.fetchDummyUser().subscribe((userDto) => {
      userDto.user.franchiserID = -1;
      this.userDto = userDto;
      this.isNew = true;
      this.setUserQuestions();
    });
  }

  disableSubmit() {
    const formValues = FormUtils.getFormValues(this.colsComponent.forms, 'userManagement.header.user');
    return this.isNew && formValues && (!formValues.password || !formValues.username || !formValues.displayName);
  }

  save() {
    const userForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'userManagement.header.user');
    const rolesForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'userManagement.header.roles');

    if (userForm.franchiserID === '-1') {
      this.snackBar.open(this.translationService.translate('userManagement.error.franchiserEmpty'), null, ERROR_SNACK_CONFIG);
      return;
    } else if (!userForm.username) {
      this.snackBar.open(this.translationService.translate('userManagement.error.loginEmpty'), null, ERROR_SNACK_CONFIG);
      return;
    }

    Object.assign(this.userDto.user, userForm);
    this.userDto.isPasswordChanged = this.userDto.user.password && this.newPassword;

    delete this.userDto.user.passwordRepeat;

    this.userDto.roleSelectionList.forEach(roleDto => {
      roleDto.isSelected = rolesForm[roleDto.role.roleID];
    });

    const result = this.isNew ? this.userManagementService.insertUser(this.userDto) : this.userManagementService.updateUser(this.userDto);
    result.subscribe({
      next: (response: any) => {
        if (response.isSuccess) {
          this.snackBar.open(
            this.translationService.translate("snackBar.saved"),
            null,
            SUCCESS_SNACK_CONFIG,
          );
          if (this.isNew) {
            this.reset();
          }
        } else if (response.isErrorStationPINExists) {
          this.snackBar.open(
            this.translationService.translate(
              "userManagement.error.stationPINExists",
            ),
            null,
            ERROR_SNACK_CONFIG,
          );
        } else if (response.isErrorUsernameExists) {
          this.snackBar.open(
            this.translationService.translate(
              "userManagement.error.usernameExists",
            ),
            null,
            ERROR_SNACK_CONFIG,
          );
        } else if (response.isErrorPasswordTooShort) {
          this.snackBar.open(
            this.translationService.translate(
              "userManagement.error.passwordTooShort",
            ),
            null,
            ERROR_SNACK_CONFIG,
          );
        }
      },
      error: () =>
        this.snackBar.open(
          this.translationService.translate("snackBar.error"),
          null,
          ERROR_SNACK_CONFIG,
        ),
    });
  }

  delete() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        'message': this.translationService.translateArgs('userManagement.confirmDeleteMessage', this.userDto.user.username)
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.userManagementService.deleteUser(this.userDto).subscribe({
          next: () => {
            this.snackBar.open(
              this.translationService.translate("snackBar.deleted"),
              null,
              SUCCESS_SNACK_CONFIG,
            );
            this.reset();
          },
          error: () =>
            this.snackBar.open(
              this.translationService.translate("snackBar.error"),
              null,
              ERROR_SNACK_CONFIG,
            ),
        });
      }
    });
  }

  onPasswordChange(pw) {
    const userForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'userManagement.header.user');
    if (pw && pw.length) {
      this.newPassword = true;
    } else {
      this.newPassword = false;
      FormUtils.getForm(this.colsComponent.forms, 'userManagement.header.user').controls['passwordRepeat'].patchValue('');
    }
    this.passwordsMatch = pw === userForm.passwordRepeat;
  }

  onPasswordRepeatChange(pw) {
    const userForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'userManagement.header.user');
    this.passwordsMatch = pw === userForm.password;
  }

  private franchiserChanged(franchiserID: number) {
    this.selectedFranchiserID = franchiserID.toString();
    // TODO: find stationID dropdown dynamically!!
    this.userManagementService.fetchUsers(franchiserID).subscribe((options) => {
      (this.selectionQuestions[0].questions[1] as DropdownQuestion).options = options;
      this.selectionQuestions[0].questions[1] = Object.assign({}, this.selectionQuestions[0].questions[1]);
      FormUtils.getForm(this.colsComponent.forms, 'userManagement.header.users').controls['user'].patchValue(null);
    });
  }

  private onUserSelected(id) {
    if (id) {
      this.userManagementService.fetchUser(id).subscribe((userDto) => {
        this.userDto = userDto;
        this.isNew = false;
        this.setUserQuestions();
      });
    }
  }

}
