import {Component, OnInit} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {TranslationService} from '../../services/translation.service';
import {ChangePasswordService} from './change-password.service';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../../../utils';

@Component({
  selector: 'vit-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  passwordOld;
  passwordNew1;
  passwordNew2;

  minLength = 8;

  constructor(public changePasswordService: ChangePasswordService,
              public snackBar: MatSnackBar,
              public translationService: TranslationService) {
  }

  ngOnInit() {
  }

  submit() {
    this.changePasswordService
      .changePassword(this.passwordOld, this.passwordNew1)
      .subscribe({
        next: () => {
          this.snackBar.open(
            this.translationService.translate("snackBar.saved"),
            null,
            SUCCESS_SNACK_CONFIG,
          );
          this.passwordOld = "";
          this.passwordNew1 = "";
          this.passwordNew2 = "";
        },
        error: () =>
          this.snackBar.open(
            this.translationService.translate("snackBar.error"),
            null,
            ERROR_SNACK_CONFIG,
          ),
      });
  }

}
